import { Fragment, useEffect, useRef, memo } from 'react';
import style from './ImageBoard.module.scss';

import { BX_HPCD, OHN, VT_TC } from '../../assets/assets';
import { images } from '../../assets/assets';

function ImageBoard({ boardMatrix, nuocCo, bamVaoOCoFunc, bamVaoQuanCoFunc, gocBanCo, isDisable, chessman }) {
    const canvaRef = useRef();

    const makerOBamVao = (name) => {
        if (name === nuocCo.duoiTenLua.name)
            return "bg_duoi_ten_lua"
        if (name === nuocCo.dauTenLua.name)
            return "bg_dau_ten_lua"
        if (name === nuocCo.oBenDap.name)
            return "bg_ben_dap"
    }
    // Hàm này dùng để dánh dấu màu vàng ô cờ
    const markerSquare = (quanCo) => {
        if (quanCo === null) return null;
        if (quanCo.number === 0 || quanCo.number === 1) {
            if (chessman !== null && quanCo.number === chessman) {
                if (isDisable) {
                    return style.bg_red;
                }
                return style.bg_green;
            }
        }
        if (quanCo.isMarker === true) {
            return style.marker;
        }
        return null;
    }

    useEffect(() => {
        /*
        Màu ban đầu chọn:

        + màu ô cờ đậm:
            const mauOCoDen = '#FF8C00'; // dark orange
            const mauOCoDen = '#789954'; // dark green
        
        + Màu ô cờ trắng:
            const mauOCoTrang = '#e9f0e2';

        + Màu viền bàn cờ:
            const mauVienBanCo = '#D2691E';

        + Màu bánh xe hạnh phúc và các hình tam giác
            const mauVienBanCo = '#D2691E';
        */
        const darkRed = '#996600';
        const mauVienBanCo = '#8B4500';

        const mauOCoTrang = '#e9f0e2';
        const mauOCoDen = '#CD853F';


        const canvas = canvaRef.current;
        const ctx = canvas.getContext('2d');

        const squareSize = 58; // Kích thước ô cờ mới là 60
        const borderSize = 20; // Kích thước viền 20
        const boardSize = squareSize * 8;
        const imgSize = 28;

        // canvas.addEventListener('touchmove', e, { passive: true });
        // canvas.addEventListener('touchstart', e, { passive: true });

        // Vẽ viền màu cam
        {
            // Vẽ viền trên
            // ctx.fillStyle = '#FFA500'; // Màu cam vàng
            ctx.fillStyle = mauVienBanCo; // Màu cam vàng
            ctx.fillRect(0, 0, boardSize + borderSize * 2, borderSize);
            // Viền bên trái
            ctx.fillRect(0, borderSize, borderSize, boardSize + borderSize);
            // Viền dưới
            ctx.fillRect(0, borderSize + boardSize, boardSize + borderSize * 2, borderSize);
            // Viền bên trái
            ctx.fillRect(boardSize + borderSize, borderSize, borderSize, boardSize + borderSize);
        }

        // Vẽ bàn cờ vua và viền mỏng 1px cho từng ô
        for (let i = 0; i < 8; i++) {
            for (let j = 0; j < 8; j++) {
                let color = (i + j) % 2 === 0 ? mauOCoTrang : mauOCoDen; // Xen kẽ màu trắng và đen
                if (i === 0 || j === 0 || i === 7 || j === 7) {
                    color = mauOCoTrang;
                }
                ctx.fillStyle = color;
                ctx.fillRect(j * squareSize + borderSize, i * squareSize + borderSize, squareSize, squareSize);

                ctx.strokeStyle = '#000'; // Màu đen cho đường viền
                ctx.lineWidth = 1; // Độ rộng của đường viền là 1px
                ctx.strokeRect(j * squareSize + borderSize, i * squareSize + borderSize, squareSize, squareSize);

                // Vẽ tam giác vuông tại ô i=1, j=1
                if (i === 1 && j === 1) {
                    ctx.beginPath();
                    ctx.moveTo(j * squareSize + borderSize, i * squareSize + borderSize); // Đỉnh dưới bên trái
                    ctx.lineTo((j + 1) * squareSize + borderSize, i * squareSize + borderSize); // Đỉnh dưới bên phải
                    ctx.lineTo(j * squareSize + borderSize, (i + 1) * squareSize + borderSize); // Đỉnh trên bên trái
                    ctx.closePath();
                    ctx.fillStyle = darkRed; // Màu đỏ cho tam giác
                    ctx.fill();
                }
            }
        }

        // vẽ tam giác
        {
            // Vẽ tam giác vuông tại ô i=6, j=1
            ctx.beginPath();
            ctx.moveTo(squareSize + borderSize, 7 * squareSize + borderSize); // Đỉnh trên bên trái
            ctx.lineTo(squareSize + borderSize, 6 * squareSize + borderSize); // Đỉnh trên bên phải
            ctx.lineTo(squareSize + borderSize, 7 * squareSize + borderSize); // Đỉnh dưới bên trái
            ctx.closePath();
            ctx.fillStyle = darkRed; // Màu xanh lá cho tam giác
            ctx.fill();

            // Vẽ tam giác vuông tại ô i=6, j=1
            ctx.beginPath();
            ctx.moveTo(borderSize + squareSize, squareSize * 7 + borderSize); // Đỉnh dưới bên trái (góc vuông)
            ctx.lineTo(borderSize + squareSize, squareSize * 6 + borderSize); // Đỉnh trên bên trái
            ctx.lineTo(squareSize * 2 + borderSize, squareSize * 7 + borderSize); // Đỉnh dưới bên phải
            ctx.closePath();
            ctx.fillStyle = darkRed; // Màu xanh lá cho tam giác
            ctx.fill();

            // Vẽ tam giác vuông tại ô i=1, j=6
            ctx.beginPath();
            ctx.moveTo(squareSize * 7 + borderSize, borderSize + squareSize); // Đỉnh trên bên phải
            ctx.lineTo(squareSize * 6 + borderSize, borderSize + squareSize); // Đỉnh trên bên trái
            ctx.lineTo(squareSize * 7 + borderSize, borderSize + squareSize * 2); // Đỉnh dưới bên phải
            ctx.closePath();
            ctx.fillStyle = darkRed; // Màu xanh lá cho tam giác
            ctx.fill();

            // Vẽ tam giác vuông tại ô i=6, j=6
            ctx.beginPath();
            ctx.moveTo(7 * squareSize + borderSize, 7 * squareSize + borderSize); // Đỉnh trên bên trái
            ctx.lineTo(7 * squareSize + borderSize, 6 * squareSize + borderSize); // Đỉnh trên bên phải
            ctx.lineTo(6 * squareSize + borderSize, 7 * squareSize + borderSize); // Đỉnh dưới bên trái
            ctx.closePath();
            ctx.fillStyle = darkRed; // Màu xanh lá cho tam giác
            ctx.fill();
        }

        // Vẽ vòng tròn thành công ở mỗi tam giác
        {
            // Load hình ảnh
            const img_VT_TC = new Image();
            img_VT_TC.src = VT_TC;

            // Khi hình ảnh đã được load
            img_VT_TC.onload = function () {
                ctx.drawImage(img_VT_TC, 6.25 * squareSize + borderSize, 1.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_VT_TC, 1.25 * squareSize + borderSize, 1.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_VT_TC, 6.25 * squareSize + borderSize, 6.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_VT_TC, 1.25 * squareSize + borderSize, 6.25 * squareSize + borderSize, imgSize, imgSize);
            }
        }

        // Vẽ viền màu đỏ kích thước 6x6 từ ô i=1, j=1 đến i=7, j=7
        ctx.strokeStyle = darkRed; // Màu đỏ cho viền
        ctx.lineWidth = 4; // Độ rộng của đường viền là 1px
        ctx.strokeRect(squareSize + borderSize, squareSize + borderSize, 6 * squareSize, 6 * squareSize);


        // Vẽ ông hội nhập
        {
            // Load hình ảnh
            const img_OHN = new Image();
            img_OHN.src = OHN;

            // Khi hình ảnh đã được load
            img_OHN.onload = function () {
                // ctx.drawImage(img_OHN, 3.25 * squareSize + borderSize, 0.25 * squareSize + borderSize, imgSize, imgSize);
                // Quay hình ảnh ngược lại 180 độ

                ctx.save(); // Lưu trạng thái canvas hiện tại
                ctx.translate(borderSize + squareSize * 3.75, borderSize + 0.75 * squareSize); // Đặt tọa độ quay ảnh
                ctx.rotate(Math.PI); // Quay ảnh theo trục Z
                ctx.drawImage(img_OHN, 0, 0, imgSize, imgSize); // Vẽ hình ảnh

                // Đặt lại trạng thái canvas
                ctx.restore();

                ctx.drawImage(img_OHN, 4.25 * squareSize + borderSize, 7.25 * squareSize + borderSize, imgSize, imgSize);
            }
        }

        // Vẽ bánh xe hạnh phúc cuộc đời
        {
            // Vẽ các đường thẳng
            ctx.beginPath();
            // Từ ô i=0, j=5 đến các ô còn lại
            ctx.moveTo(4.5 * squareSize + borderSize, 0.5 * squareSize + borderSize); // Tâm ô i=0, j=5
            ctx.lineTo(4.5 * squareSize + borderSize, 1.5 * squareSize + borderSize); // Tâm ô i=1, j=5
            ctx.lineTo(3.5 * squareSize + borderSize, 2.5 * squareSize + borderSize); // Tâm ô i=2, j=5
            ctx.lineTo(3.5 * squareSize + borderSize, 3.5 * squareSize + borderSize); // Tâm ô i=3, j=5

            ctx.lineTo(4.5 * squareSize + borderSize, 4.5 * squareSize + borderSize); // Tâm ô i=4, j=5
            ctx.lineTo(4.5 * squareSize + borderSize, 5.5 * squareSize + borderSize); // Tâm ô i=5, j=5
            ctx.lineTo(3.5 * squareSize + borderSize, 6.5 * squareSize + borderSize); // Tâm ô i=6, j=5
            ctx.lineTo(3.5 * squareSize + borderSize, 7.5 * squareSize + borderSize); // Tâm ô i=7, j=5

            // Kết thúc vẽ đường thẳng
            ctx.strokeStyle = darkRed; // Màu đỏ
            ctx.lineWidth = 6; // Độ rộng của đường thẳng
            ctx.stroke();

            // Load hình ảnh
            const img_BXHPCD = new Image();
            img_BXHPCD.src = BX_HPCD;

            // Khi hình ảnh đã được load
            img_BXHPCD.onload = function () {
                // Vẽ hình ảnh vào ô i=0, j=5 và i=1, j=5
                ctx.drawImage(img_BXHPCD, 4.25 * squareSize + borderSize, 0.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_BXHPCD, 4.25 * squareSize + borderSize, 1.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_BXHPCD, 4.25 * squareSize + borderSize, 4.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_BXHPCD, 4.25 * squareSize + borderSize, 5.25 * squareSize + borderSize, imgSize, imgSize);

                ctx.drawImage(img_BXHPCD, 3.25 * squareSize + borderSize, 2.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_BXHPCD, 3.25 * squareSize + borderSize, 3.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_BXHPCD, 3.25 * squareSize + borderSize, 6.25 * squareSize + borderSize, imgSize, imgSize);
                ctx.drawImage(img_BXHPCD, 3.25 * squareSize + borderSize, 7.25 * squareSize + borderSize, imgSize, imgSize);
            }
        }

        // vẽ chữ và số trên viền
        {
            // Vẽ số và chữ trên các viền của bàn cờ
            ctx.fillStyle = '#000';
            ctx.font = '16px Arial';

            const rowLabels = ['8', '7', '6', '5', '4', '3', '2', '1'];
            const colLabels = ['A', 'B', 'C', 'D', 'E', 'G', 'H', 'I'];
            for (let i = 0; i < 8; i++) {
                // Vẽ số trên viền bên trái
                ctx.fillText(rowLabels[i], borderSize - 15, squareSize * (2 * i + 1) / 2 + borderSize);
                // Vẽ chữ cái trên viền bên dưới
                ctx.fillText(colLabels[i], squareSize * (2 * i + 1) / 2 + borderSize, boardSize + borderSize + 20);
            }

            ctx.save(); // Lưu trạng thái hiện tại của context
            // Tiến hành vẽ ngược
            ctx.translate(boardSize + borderSize * 2, borderSize * 2 + boardSize);
            ctx.rotate(Math.PI); // Quay 180 độ
            // Vì đảo ngược rồi nên trên sẽ thành dưới, trái thành phải
            for (let i = 0; i < 8; i++) {
                // Vẽ số trên viền bên trái
                ctx.fillText(rowLabels[7 - i], borderSize - 15, squareSize * (2 * i + 1) / 2 + borderSize);
                // Vẽ chữ cái trên viền bên dưới
                ctx.fillText(colLabels[7 - i], squareSize * (2 * i + 1) / 2 + borderSize, boardSize + borderSize + 20);
            }
            // Kết thúc vẽ ngược
            ctx.restore() // Quay lại tráng thái ban đầu
        }

    }, [])
    return (
        <Fragment>
            <canvas className={style.ImageBoard} ref={canvaRef} width="504" height="504"></canvas>
            <div className={`${style.Board}`}>
                {
                    boardMatrix.map((row, rowIndex) => (
                        <Fragment key={rowIndex}>
                            {
                                row.map((col, colIndex) => (
                                    <div key={colIndex}
                                        className={`${style.square} ${markerSquare(col)} ${isDisable ? style.is_disable : null} ${makerOBamVao(col.name)}`}
                                        onClick={() => bamVaoOCoFunc(col.number, col.name, rowIndex, colIndex)}>
                                        {
                                            col.number !== null
                                                ?
                                                <div
                                                    onClick={(event) => bamVaoQuanCoFunc(col.number, col.isMarker, rowIndex, colIndex)}
                                                    style={{ backgroundImage: `url(${images[col.number].image})`, transform: `rotateZ(${gocBanCo}deg)` }}
                                                    className={style.quan_co} />
                                                : <Fragment />
                                        }
                                    </div>
                                ))
                            }
                        </Fragment>))
                }
            </div>
        </Fragment>
    )
};

export default memo(ImageBoard);
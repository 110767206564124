import { useLayoutEffect, useState } from 'react';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        window.addEventListener('orientationchange', updateSize);
        document.addEventListener('DOMContentLoaded', updateSize);

        updateSize();
        return () => {
            window.removeEventListener('resize', updateSize);
            window.removeEventListener('orientationchange', updateSize);
            document.removeEventListener('DOMContentLoaded', updateSize);
        }
    }, []);
    return size;
}

export { useWindowSize };
import { useEffect, useRef, useState } from 'react';
import style from './Chessman.module.scss';
import { images } from '../../assets/assets';

function Chessman({ quanCoBamVao, gocBanCo, tatXemCoFunc, clientId, turnState, boardSize }) {
    const [visible, setVisible] = useState({ visible: false, idQuanCo: quanCoBamVao.idQuanCo, top: boardSize, left: boardSize, size: 0 });
    const [outAnimation, setOutAnimation] = useState(null);
    const idTimeOut = useRef(null);

    useEffect(() => {
        if (quanCoBamVao.idQuanCo === null) {
            setOutAnimation(style.animateOut);
            idTimeOut.current = setTimeout(() => {
                setVisible({ visible: false, idQuanCo: null });
            }, (1200));
        } else {
            const kichThuocBanCo = parseInt(boardSize);
            const vienBanCo = kichThuocBanCo * 0.04;
            const motCoCo = (kichThuocBanCo * 0.92) / 8;

            const kichThuocQuanCo = motCoCo * 0.68;
            const toaDoX = vienBanCo + (quanCoBamVao.col * motCoCo) + (motCoCo * 0.16);
            const toaDoY = vienBanCo + (quanCoBamVao.row * motCoCo) + (motCoCo * 0.16);
            setVisible({ visible: true, idQuanCo: quanCoBamVao.idQuanCo, top: toaDoY, left: toaDoX, size: kichThuocQuanCo });
            setOutAnimation(null);
        }
    }, [quanCoBamVao.idQuanCo]);

    const bamVaoQuanCoTo = () => {
        if (clientId === turnState) {
            tatXemCoFunc();
        }
    }


    useEffect(() => {
        return () => {
            clearTimeout(idTimeOut.current);
        }
    }, [])
    return (
        <div
            style={{ display: visible.visible ? "block" : "none", }}
            className={style.ChessmanContainer}
            onClick={() => bamVaoQuanCoTo()}
        >
            <div
                style={{
                    height: `${visible.size}px`,
                    width: `${visible.size}px`,
                    top: `${visible.top}px`,
                    left: `${visible.left}px`,

                    backgroundImage: `url(${visible.idQuanCo ? images[visible.idQuanCo].image : null})`,
                    transform: `rotateZ(${gocBanCo}deg)`,
                }}
                className={`${style.Chessman} ${outAnimation}`}
            />
        </div>
    )
};

export default Chessman;
import BX_HPCD from './ban_co/BX_HPCD.png';
import OHN from './ban_co/OHN.png';
import VT_TC from './ban_co/VT_TC.png';

import chu_dong from './doanh_nhan/chu_dong.png';
import thu_dong from './doanh_nhan/thu_dong.png';

import thanh_cong from './thanh_cong/thanh_cong.png';
import thanh_cong_cac_ben from './thanh_cong/thanh_cong_cac_ben.png';
import thanh_cong_do_ta from './thanh_cong/thanh_cong_do_ta.png';
import thanh_cong_giai_phap from './thanh_cong/thanh_cong_giai_phap.png';
import thanh_cong_gia_tri from './thanh_cong/thanh_cong_gia_tri.png';
import thanh_cong_hoi_nhap from './thanh_cong/thanh_cong_hoi_nhap.png';
import thanh_cong_lam_chu from './thanh_cong/thanh_cong_lam_chu.png';

import phuong_phap_bai_hoc from './phuong_phap/phuong_phap_bai_hoc.png';
import phuong_phap_chu_dong from './phuong_phap/phuong_phap_chu_dong.png';
import phuong_phap_hay from './phuong_phap/phuong_phap_hay.png';
import phuong_phap_hoc_sieu from './phuong_phap/phuong_phap_hoc_sieu.png';
import phuong_phap_moi_ta from './phuong_phap/phuong_phap_moi_ta.png';
import phuong_phap_mot_chut from './phuong_phap/phuong_phap_mot_chut.png';
import phuong_phap_mo_nao from './phuong_phap/phuong_phap_mo_nao.png';
import phuong_phap_nao_gioi from './phuong_phap/phuong_phap_nao_gioi.png';
import phuong_phap_quan_sat from './phuong_phap/phuong_phap_quan_sat.png';

import tu_duy_dung from './tu_duy/tu_duy_dung.png';
import tu_duy_muc_tieu from './tu_duy/tu_duy_muc_tieu.png';
import tu_duy_ngo_toi from './tu_duy/tu_duy_ngo_toi.png';
import tu_duy_su_menh from './tu_duy/tu_duy_su_menh.png';
import tu_duy_thich_ung from './tu_duy/tu_duy_thich_ung.png';
import tu_duy_tich_cuc from './tu_duy/tu_duy_tich_cuc.png';
import tu_duy_tran_quy from './tu_duy/tu_duy_tran_quy.png';

import hanh_dong_dam_me from './hanh_dong/hanh_dong_dam_me.png';
import hanh_dong_ly_tinh from './hanh_dong/hanh_dong_ly_tinh.png';
import hanh_dong_qua_ta from './hanh_dong/hanh_dong_qua_ta.png';
import hanh_dong_tap_trung from './hanh_dong/hanh_dong_tap_trung.png';
import hanh_dong_thanh_cong from './hanh_dong/hanh_dong_thanh_cong.png';
import hanh_dong_thuc from './hanh_dong/hanh_dong_thuc.png';
import hanh_dong_viec_nay from './hanh_dong/hanh_dong_viec_nay.png';

import ban_co_full from './anh_phu/ban_co_full.JPG';
import anh_robot from './anh_phu/robot_image.png';
import black_image from './anh_phu/black_image.png';
import favicon from './anh_phu/favicon.ico';

const images = {
  '0': {
    name: "Doanh nhân thụ động",
    image: thu_dong
  },
  '1': {
    name: "Doanh nhân chủ động",
    image: chu_dong
  },
  '4': {
    name: "Kết quả thành công",
    image: thanh_cong
  },
  '8': {
    name: "Thành công các bên",
    image: thanh_cong_cac_ben
  },
  '12': {
    name: "Thành công do ta",
    image: thanh_cong_do_ta
  },
  '16': {
    name: "Thành công giá trị",
    image: thanh_cong_gia_tri
  },
  '20': {
    name: "Thành công giải pháp",
    image: thanh_cong_giai_phap
  },
  '24': {
    name: "Thành công hội nhập",
    image: thanh_cong_hoi_nhap
  },
  '28': {
    name: "Thành công làm chủ",
    image: thanh_cong_lam_chu
  },

  '5': {
    name: "Phương pháp hay",
    image: phuong_phap_hay
  },
  '9': {
    name: "Phương pháp bài học",
    image: phuong_phap_bai_hoc
  },
  '13': {
    name: "Phương pháp chủ động",
    image: phuong_phap_chu_dong
  },
  '17': {
    name: "Phương pháp học siêu",
    image: phuong_phap_hoc_sieu
  },
  '21': {
    name: "Phương pháp mở não",
    image: phuong_phap_mo_nao
  },
  '25': {
    name: "Phương pháp mới ta",
    image: phuong_phap_moi_ta
  },
  '29': {
    name: "Phương pháp một chút",
    image: phuong_phap_mot_chut
  },
  '33': {
    name: "Phương pháp não giỏi",
    image: phuong_phap_nao_gioi
  },
  '37': {
    name: "Phương pháp quan sát",
    image: phuong_phap_quan_sat
  },
  '6': {
    name: "Tư duy đúng",
    image: tu_duy_dung
  },
  '10': {
    name: "Tư duy mục tiêu",
    image: tu_duy_muc_tieu
  },
  '14': {
    name: "Tư duy ngộ tới",
    image: tu_duy_ngo_toi
  },
  '18': {
    name: "Tư duy sứ mệnh",
    image: tu_duy_su_menh
  },
  '22': {
    name: "Tư duy thích ứng",
    image: tu_duy_thich_ung
  },
  '26': {
    name: "Tư duy tích cực",
    image: tu_duy_tich_cuc
  },
  '30': {
    name: "Tư duy trân quý",
    image: tu_duy_tran_quy
  },
  '7': {
    name: "Hành động thực",
    image: hanh_dong_thuc
  },
  '11': {
    name: "Hành động đam mê",
    image: hanh_dong_dam_me
  },
  '15': {
    name: "Hành động lý tình",
    image: hanh_dong_ly_tinh
  },
  '19': {
    name: "Hành động quả ta",
    image: hanh_dong_qua_ta
  },
  '23': {
    name: "Hành động tập trung",
    image: hanh_dong_tap_trung
  },
  '27': {
    name: "Hành động thành công",
    image: hanh_dong_thanh_cong
  },
  '31': {
    name: "Hành động việc nay",
    image: hanh_dong_viec_nay
  },
  '40': {
    name: "Quân cờ màu đen",
    image: black_image
  }
}
export {
  BX_HPCD,
  OHN,
  VT_TC,
  images,
  ban_co_full,
  anh_robot,
  favicon
};
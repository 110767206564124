import style from './CenterBox.module.scss';

function CenterBox({ visible, children }) {

    return (
        <div className={style.CenterBox}
            style={{ display: `${visible ? "flex" : "none"}` }}>
            <div className={style.container}>
                {children}
            </div>
        </div>
    )
};

export default CenterBox;
import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import ViewBoard from './ViewBoard';
import style from './ViewRoom.module.scss';
import RoomHeader from './RoomHeader';

function ViewRoom() {
    // [{ id: "", ten: "Người chơi", id_anh: "", diem: 0, time: 0 }, { id: "", ten: "Người chơi", id_anh: "", diem: 0, time: 0 }]
    const [thongTinHeader, setThongTinHeader] = useState([{ id: "", ten: "Người chơi", id_anh: 4, diem: 0, time: 0 }, { id: "", ten: "Người chơi", id_anh: 4, diem: 0, time: 0 }]);
    const [notify, setNotify] = useState("");
    const { idRoom } = useParams();
    // const [times, setTimes] = useState([{ id: null, time: null }, { id: null, time: null }]);

    const capNhatDiem = useCallback((scores) => {
        setThongTinHeader(prev => {
            const updateData = [...prev];
            updateData.forEach((oldItem) => {
                oldItem.diem = scores[oldItem.id]
            });
            return updateData;
        })
    }, [setThongTinHeader]);

    const capNhatThoiGian = (newTime) => {
        // newTime = [{id: "", time: 0},{id: "", time: 0}]
        setThongTinHeader(prev => {
            const updateData = [...prev];
            updateData.forEach((oldItem) => {
                const index = newTime.findIndex((newTimeItem) => oldItem.id === newTimeItem.id);
                if (index !== -1) {
                    oldItem.time = newTime[index].time;
                }
            });
            return updateData;
        })
    }

    const capNhatThongTin = (newInfo) => {
        // console.log(newInfo);
        setThongTinHeader(newInfo.map(value => ({
            id: value.id,
            ten: value.name,
            diem: 0,
            time: 0,
            id_anh: value.chessman
        })))
    }

    return (
        <div className={style.ViewRoom}>
            <RoomHeader
                thong_bao={notify}
                info={thongTinHeader}
            />
            <ViewBoard
                roomId={idRoom}
                setThongBaoFunc={setNotify}
                capNhatDiemFunc={capNhatDiem}
                setTimesFunc={capNhatThoiGian}
                capNhatThongTinFunc={capNhatThongTin}
            />
        </div>
    )
};

export default ViewRoom;


function RulePlayPage() {

    return (
        <iframe src="https://trochoichuviet.com/huong-dan-co-chu-viet" frameborder="0" width="100%" height="800px"
            sandbox="allow-same-origin allow-scripts"
        >

        </iframe>
    )
};

export default RulePlayPage;
import { useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import PlayBoard from './PlayBoard';
import style from './Room.module.scss';
import RoomHeader from './RoomHeader';
import ChatBox from '../chat/ChatBox';

import { getClientId } from '../../constants/services';
function Room() {
    const [thongTinHeader, setThongTinHeader] = useState({ ten_toi: "Tôi", anh_cua_toi: "", ten_doi_thu: "Đối thủ", anh_doi_thu: "", diem_toi: 0, diem_doi_thu: 0 });
    const [notify, setNotify] = useState("");
    const { idRoom } = useParams();
    const clientId = useRef(getClientId());
    const [times, setTimes] = useState([{ id: null, time: null }, { id: null, time: null }]);

    const capNhatDiem = useCallback((scores) => {
        // nhận vào dữ liệu có dạng: {id1: score1, id2: score2}
        // nhiệm vụ lấy điểm của tôi và của đối thủ ra để
        let opponentId = Object.keys(scores).find(key => key !== clientId.current);
        setThongTinHeader(prevThongTin => ({
            ...prevThongTin,
            diem_toi: scores[clientId.current],
            diem_doi_thu: scores[opponentId]
        }));
    }, [setThongTinHeader]);

    return (
        <div className={style.Room}>
            <RoomHeader
                ten_toi={thongTinHeader.ten_toi}
                anh_cua_toi={thongTinHeader.anh_cua_toi}
                ten_doi_thu={thongTinHeader.ten_doi_thu}
                anh_doi_thu={thongTinHeader.anh_doi_thu}
                diem_toi={thongTinHeader.diem_toi}
                diem_doi_thu={thongTinHeader.diem_doi_thu}
                thong_bao={notify}
                clientId={clientId.current}
                times={times}
            />
            <PlayBoard
                clientId={clientId.current}
                roomId={idRoom}
                setThongBaoFunc={setNotify}
                capNhatDiemFunc={capNhatDiem}
            />
            <ChatBox
                setTimesFunc={setTimes}
                clientId={clientId.current}
                roomId={idRoom}
                thongTinHeader={thongTinHeader}
                setThongTinHeaderFunc={setThongTinHeader} />
        </div>
    )
};

export default Room;

import { Link, useNavigate } from 'react-router-dom';
import style from './ListRoom.module.scss';
import Login from '../login/Login';
import { Fragment, useEffect, useState } from 'react';
import { secondsToMinutes } from '../../constants/services';

import { AiFillRobot } from "react-icons/ai";
// import { AiOutlineGlobal, AiOutlineSearch, AiFillRobot, AiOutlinePlusCircle } from "react-icons/ai";
import { GiRuleBook } from "react-icons/gi";

import { api, getAPI, methods } from '../../constants/api';
import { ban_co_full } from '../../assets/assets';
import { getPlayerInfo } from '../../constants/services';

function ListRoom() {
    const [listRoomOnline, setListRoomOnline] = useState([]);
    // const listRoomOnline = [
    //     { idRoom: "12345", isUnViewAble: false, isUnPlayAble: true, time: 2, players: ["Chim Sẻ đi nắng", "Cá Trích ẩn danh"] },
    //     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
    //     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
    //     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
    //     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
    //     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
    //     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
    //     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
    // ];
    const [loginVisible, setLoginVisible] = useState(false);
    const nav = useNavigate();
    const viewFunc = (idRoom) => {
        nav(`/view/${idRoom}`);
    }

    const joinFunc = (idRoom) => {
        const { name } = getPlayerInfo();
        if (name) {
            nav(`/room/${idRoom}`);
        } else {
            setLoginVisible(true);
        }
    }

    // useEffect(() => {
    //     fetch(getAPI(api.get_list_room))
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(data => {
    //             setListRoomOnline(data);
    //         })
    //         .catch(error => {
    //             console.error('There was a problem with the fetch operation:', error);
    //         });

    // }, []);

    useEffect(() => {
        const eventSource = new EventSource(getAPI(api.sse_get_list_room));

        eventSource.onmessage = function (event) {
            const data = JSON.parse(event.data);
            setListRoomOnline(data);
            // console.log('Received data:', data);
        };

        eventSource.onerror = function (error) {
            console.error('EventSource failed:', error);
        };

    }, [])

    return (
        <Fragment>
            <Login visible={loginVisible} setVisibleFunc={setLoginVisible} />
            <div className='ListRoom'>
                <div className={style.container}>
                    <h1>Trò chơi chữ việt</h1>
                    <div className={style.table_header}>
                        <div style={{ backgroundImage: `url('${ban_co_full}')` }} className={style.ban_co_full}></div>
                        <div className={style.chuc_nang}>
                            {/* <Link to={""} className={`${style.chuc_nang_btn} btn-disabled`}><span className={style.btn_icon}><AiOutlineGlobal /></span>Chơi ngẫu nhiên</Link> */}
                            {/* <Link to={""} className={`${style.chuc_nang_btn} btn-disabled`}><span className={style.btn_icon}><AiOutlineSearch /></span>Tìm màn chơi</Link> */}
                            <Link to={"/chơi-với-robot"} className={`${style.chuc_nang_btn}`}><span className={style.btn_icon}><AiFillRobot /></span>Chơi với máy</Link>
                            <Link to={"/luật-chơi"} className={`${style.chuc_nang_btn}`}><span className={style.btn_icon}><GiRuleBook /></span>Luật chơi</Link>
                            {/* <Link to={""} className={`${style.chuc_nang_btn} btn-disabled`}><span className={style.btn_icon}><AiOutlinePlusCircle /></span>Tạo giải đấu</Link> */}
                        </div>
                    </div>
                    <table className={style.custom_table}>
                        <thead>
                            <tr className={style.head_row}>
                                <th>Id phòng</th>
                                <th>Thời gian</th>
                                <th>Người tham gia</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listRoomOnline.map((row) => (
                                    <tr key={row.idRoom} className={style.body_row}>
                                        <td>{row.idRoom}</td>
                                        <td>{secondsToMinutes(row.time)}</td>
                                        <td>
                                            {row.players.map((user, index) => (
                                                <div className={style.set_width_on_mobile} key={index}>{user}</div>
                                            ))}
                                        </td>
                                        <td>
                                            <button
                                                disabled={row.isUnViewAble}
                                                className={`${style.view_room} ${style.bg_orange}`}
                                                onClick={() => viewFunc(row.idRoom)}>Xem</button>
                                            <button
                                                disabled={row.isUnPlayAble}
                                                className={`${style.join_room} ${style.bg_blue}`}
                                                onClick={() => joinFunc(row.idRoom)}>Tham gia</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </div>
            </div>
        </Fragment>
    )
};

export default ListRoom;
// const listRoomOnline = [
//     { idRoom: "12345", isUnViewAble: false, isUnPlayAble: true, time: 2, players: ["Chim Sẻ đi nắng", "Cá Trích ẩn danh"] },
//     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
//     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
//     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
//     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
//     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
//     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
//     { idRoom: "12776", isUnViewAble: false, isUnPlayAble: false, time: 3, players: ["Gấu trúc tham ăn"] },
// ];
import style from './LeftSidebar.module.scss';
import { Link } from 'react-router-dom';
import { publicLeftSidebarUrl } from '../../constants/routes';
import { getPlayerInfo, removePlayerInfo } from '../../constants/services';
import { Fragment, useState } from 'react';
import SaveData from '../../components/login/SaveData';

function Header() {
    const { name, score } = getPlayerInfo();
    const [showLogoutState, setShowLogoutState] = useState(name ? true : false);
    const [saveVisible, setSaveVisible] = useState(false);

    const logout = () => {
        const logoutState = window.confirm("Bạn có muốn lưu thông tin cá nhân?");
        if (logoutState) {
            setSaveVisible(true);
        }
        else {
            removePlayerInfo();
            setShowLogoutState(false);
        }
    }

    return (
        <Fragment>
            <header className={style.Header}>
                <div className={style.account}>
                    {/* <h2>Trò chơi chữ Việt</h2> */}
                    <h2>{showLogoutState ? `${name} - ${parseInt(score)}` : "Trò chơi chữ Việt"}</h2>
                    {
                        showLogoutState
                            ?
                            <button className={style.logout_btn} onClick={logout}>Đăng xuất</button>
                            : null
                    }
                </div>
                <div className={style.about}>
                    <ul className={style.fa_list}>
                        {
                            publicLeftSidebarUrl.map((row, rowIndex) => (
                                <li key={rowIndex}>
                                    <ul className={style.chi_list}>
                                        {
                                            row.map((item, itemIndex) => (
                                                <li key={itemIndex}>
                                                    <Link className={style.link} to={item.url}>
                                                        <span>{item.icon}</span>
                                                        <span>{item.title}</span>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <p style={{color: "gray", fontSize: "1.7rem", marginTop: "1.2rem"}}>Phiên bản đang trong quá trình vận hành thử nghiệm, bản quyền <b>@codoanhnhan.com</b></p>
            </header>

            <SaveData setVisibleFunc={setSaveVisible} visible={saveVisible}></SaveData>
        </Fragment>
    )
};

export default Header;

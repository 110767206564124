// const baseIP = "localhost:8000";
const baseIP = "codoanhnhan.com";

const socketBase = "wss";

// Lưu ý là khi chuyển thành server public thì dùng giao thức https và wss

const get_socket_chat = (roomId, clientId, name, score) => {
    return `${socketBase}://${baseIP}/ws/chat/${roomId}/${clientId}/${name}/${score}`
};

const get_socket_game = (roomId, clientId) => {
    return `${socketBase}://${baseIP}/ws/game/${roomId}/${clientId}`
};

const get_socket_view = (roomId) => {
    return `${socketBase}://${baseIP}/ws/view/${roomId}`
};

const getAPI = (subAPI) => {
    return `https://${baseIP}/api/${subAPI}`;
}

const getAPI_tam_thoi = (subAPI) => {
    return `https://${baseIP}/api/${subAPI}`;
}
const api = {
    get_list_room: 'list-room',
    sse_get_list_room: "sse-get-list-room",
    get_image: 'get_image',
    di_chuyen_khong_an_quan: 'check_nuoc_di/di_chuyen_khong_an_quan',
    di_chuyen_an_quan: 'check_nuoc_di/di_chuyen_an_quan',
    dau_tu: 'check_nuoc_di/dau_tu',
    luu_thong_tin: 'luu_thong_tin', // đây là api dùng để lưu thông tin đăng nhập
    lay_thong_tin: 'lay_thong_tin', // đây là api dùng để login lấy thông tin elo, tên về
}

const methods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE"
}
export { get_socket_chat, get_socket_game, get_socket_view, api, methods, getAPI, getAPI_tam_thoi };
import style from './Login.module.scss';

import { useState } from 'react';
import CenterBox from '../../base/box/CenterBox';
import { fetchData, getPlayerInfo } from '../../constants/services';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { api, methods, getAPI_tam_thoi } from '../../constants/api';

function SaveData({ visible, setVisibleFunc }) {
    const [info, setInfo] = useState({ email: "", password: "" });
    const [loginError, setLoginError] = useState("");
    const [viewState, setViewState] = useState(false);

    const closeLogin = () => {
        // Đoạn này dùng để clear những thông tin đã điền trước khi đóng component
        setInfo({ email: "", password: "" });
        setLoginError("")
        setViewState(false)
        setVisibleFunc(false);
    }

    const submit = async () => {
        const emailPattern = /@.*\.com$/;
        if (info.email === "" && info.email.trim().length === 0) {
            setLoginError("Email trống.")
        }
        else if (!emailPattern.test(info.email)) {
            setLoginError("Email phải theo định dạng _@_.com .")
        }
        else if (info.password === "" && info.password.trim().length === 0) {
            setLoginError("Mật khẩu trống.")
        }
        else if (info.password.length < 8) {
            setLoginError("Mật khẩu ít hơn 8 kí tự.");
        }
        else {
            try {
                setLoginError("");
                const { name, score } = getPlayerInfo();
                const dataSend = { ...info, elo_score: score, name: name };
                const result = await fetchData(getAPI_tam_thoi(api.luu_thong_tin), methods.post, dataSend);
                if (result.state === true) {
                    // console.log("result lưu thông tin");
                    // console.log(result);
                    closeLogin();
                } else {
                    setLoginError(result.value)
                }
            }
            catch (error) {
                console.log(error);
            }
        }

    };

    const keypressInput = async (e) => {
        if (e.keyCode === 13) {
            await submit();
        }
    }
    return (
        <CenterBox visible={visible}>
            <h2 style={{ color: "var(--blue)" }}>Lưu thông tin</h2>
            <form className={style.option_box} style={{ display: "block" }}>
                <p className={style.login_error}>{loginError}</p>
                <div className={style.input_container}>
                    <input autoComplete='email' value={info.email} onChange={(e) => setInfo({ ...info, email: e.target.value })} type="email" placeholder='email' />
                </div>

                <div className={style.input_container}>
                    <input autoComplete='current-password' value={info.password} onChange={(e) => setInfo({ ...info, password: e.target.value })} onKeyDown={keypressInput} type={viewState ? "text" : "password"} placeholder='Mật khẩu' />
                    <span onClick={() => setViewState(!viewState)} className={style.xem_mat_khau_btn}>{viewState ? <AiFillEye /> : <AiFillEyeInvisible />}</span>
                </div>
            </form>
            <div className={style.button_container}>
                <button className={`bg_white`} onClick={() => closeLogin()}>Thoát</button>
                <button className={`${true ? "bg_blue" : "bg-disabled"}`} onClick={submit}>Gửi</button>
            </div>
        </CenterBox>
    )
}

export default SaveData;
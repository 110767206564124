import style from './NPCPlayboard.module.scss';
import { Fragment, useEffect, useLayoutEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageBoard from '../../base/image_board/ImageBoard';
import { useWindowSize } from '../../hooks/useWindowSize';
import { fetchData } from '../../constants/services';
import { api, methods, getAPI } from '../../constants/api';
import { getConfirmStateFunc } from '../../constants/services';
import convertToNameMatrix from '../../constants/matrix';
import Chessman from '../chessman/Chessman';
import OptionsBox from '../options/OptionsBox';
import OanTuTi from '../oan_tu_ti/OanTuTi';
import EndGame from '../end_game/EndGame';
import { npcMatrix, initMatrix } from '../../constants/matrix';

function NPCPlayboard({ luotCuaToi, setLuotCuaToiFunc, setNotifyFunc, setDiemSofunc, diemSo }) {
    const [thongTinOption, setThongTinOption] = useState({
        chessman: 1,
        capturedPieces: [[], [], [], []],    // Mảng này chứa các quân cờ sẽ xuất hiện ở trong ô chứa các quân đầu tư
    });
    const [boardMatrix, setBoardMatrix] = useState(initMatrix);
    const [nuocCo, setNuocCo] = useState({
        duoiTenLua: {
            x: null,
            y: null,
            name: ""
        },
        dauTenLua: {
            x: null,
            y: null,
            name: ""
        },
        oBenDap: {
            x: null,
            y: null,
            name: ""
        }
    });
    const [coMoiAn, setCoMoiAn] = useState([]);
    const [idQuanCoDauTu, setIdQuanCoDauTu] = useState(null);
    const [quanCoBamVao, setQuanCoBamVao] = useState({ idQuanCo: null, x: null, y: null, size: null }); // Đây là giá trị khi bấm vào quân cờ sẽ có, phục vụ di chuyển ăn quân
    const [gameTimeout, setGameTimeOut] = useState(0);
    const [oanTuTiState, setOanTuTiState] = useState(null);
    const [endGameState, setEndGameState] = useState(false);
    const [endResult, setEndResult] = useState([]);
    const nav = useNavigate();

    /*
    loaiViTri: 
        + 0: di chuyen an quan, ko an quan
        + 3: bam vao xem quan co
    */
    const [loaiviTri, setLoaiViTri] = useState(0);

    const [width, height] = useWindowSize();
    const minWidth = width >= 740 ? '600px' : `${Math.min(width, height)}px`;

    useEffect(() => {
        setLuotCuaToiFunc(null);
        const idTime = setTimeout(() => {
            setBoardMatrix(npcMatrix);
            setLuotCuaToiFunc(true);
            setNotifyFunc("Máy tính đã đặt quân doanh nhân thụ động, giờ là lượt chơi của bạn.");
        }, 2000);

        return () => {
            if (idTime) {
                clearTimeout(idTime);
            }
        }
    }, [])

    const bamVaoOCo = async (quanCo, tenOCo, rowIndex, colIndex) => {
        // setLuotCuaToiFunc(false);
        if (loaiviTri === 0) {

            if (idQuanCoDauTu === null) {
                // loại vị trí = 3 -> đặt quân doanh nhân
                // loại set vị trí = 3 thì chỉ do backend yêu cầu frontend khi bắt đầu game

                // Quân cờ là cái quân trong bàn cờ, nếu trống là null
                if (quanCo === null) {
                    // Trường hợp 1 là chọn ô bến đáp ban đầu vì nó chưa có tên
                    if (nuocCo.oBenDap.name === "") {
                        // Ô bến đáp là ô đầu tiên, vậy nên nếu bấm vào một ô trống thì xem như reset lại nước cờ
                        setNuocCo({
                            duoiTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            dauTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            oBenDap: {
                                x: colIndex,
                                y: rowIndex,
                                name: tenOCo
                            }
                        });
                    }
                    // Trường hợp 2 ô bến đáp trùng với ô hiện tại đang bấm => muốn di chuyển không ăn quân
                    else if (nuocCo.oBenDap.name === tenOCo) {
                        await phongTenLua();
                    }
                    // Trường hợp 3 là chọn ô bến đáp khác
                    else if (nuocCo.oBenDap.name !== tenOCo) {
                        setNuocCo({
                            duoiTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            dauTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            oBenDap: {
                                x: colIndex,
                                y: rowIndex,
                                name: tenOCo
                            }
                        });
                    }
                }
                else {
                    // Trường hợp bấm vào ô có quân cờ, nếu bấm vào quân doanh nhân của mình => đứng yên
                    if (quanCo === thongTinOption.chessman) {
                        quyetDinhKhongDiChuyen();
                    }
                    // Nếu bấm vào vị trí không phải quân doanh nhân => chọn đuôi và đầu
                    else {
                        if (nuocCo.oBenDap.name !== "" && nuocCo.duoiTenLua.name === "") {
                            setNuocCo({
                                ...nuocCo,
                                duoiTenLua: {
                                    x: colIndex,
                                    y: rowIndex,
                                    name: tenOCo
                                }
                            });
                        }
                        else if (nuocCo.oBenDap.name !== "" && nuocCo.duoiTenLua.name !== "" && nuocCo.dauTenLua.name === "") {
                            setNuocCo({
                                ...nuocCo,
                                dauTenLua: {
                                    x: colIndex,
                                    y: rowIndex,
                                    name: tenOCo
                                }
                            });
                        }
                    }
                }
            }
            else {
                await dauTu(rowIndex, colIndex);
            }
        }
    };

    const bamVaoQuanCo = (quanCo, isMarker, rowIndex, colIndex) => {
        if (loaiviTri === 3 && isMarker === true && quanCo !== null) {
            setQuanCoBamVao({ idQuanCo: quanCo, row: rowIndex, col: colIndex });
            if (coMoiAn.length > 0) {
                // Lấy vị trí đầu đầu tiên của mảng (vì phải bấm từ đuôi lên đầu)
                let [row0, col0] = coMoiAn[0];
                if (rowIndex === row0 && colIndex === col0) {
                    const updatedMatrix = [...boardMatrix];
                    updatedMatrix[row0][col0].isMarker = false;
                    updatedMatrix[row0][col0].number = null;
                    setCoMoiAn(prev => {
                        const coMoiAnNew = [...prev];
                        coMoiAnNew.shift();
                        return coMoiAnNew;
                    });
                    setBoardMatrix(updatedMatrix);
                }
            }
        }
    };

    // ================================= Đoạn code của người =================================================
    const dauTu = async (rowIndex, colIndex) => {
        if (idQuanCoDauTu !== null) {
            const integerMatrix = boardMatrix.map(row => row.map(item => item.number));
            const dataSend = { matrix: integerMatrix, data: { row: rowIndex, col: colIndex, chessman: idQuanCoDauTu } };
            try {
                const result = await fetchData(getAPI(api.dau_tu), methods.post, dataSend);
                if (result.state === true) {
                    setDiemSofunc(diemTruoc => {
                        const { diemToi, doiThu } = diemTruoc;
                        return { diemToi: diemToi - 1, doiThu: doiThu };
                    });
                    setThongTinOption(prev => {
                        const thongTinOptitonNew = { ...prev };
                        // Đây là quân cờ đã đầu tư, giờ xóa khỏi túi cờ
                        const quanCoDaDauTu = idQuanCoDauTu;
                        // quanCoDaDauTu là một số hợp lệ
                        if (!isNaN(quanCoDaDauTu) && typeof quanCoDaDauTu === 'number') {
                            // Kiểm tra sự tồn tại của mảng con
                            if (thongTinOptitonNew.capturedPieces[quanCoDaDauTu % 4]) {
                                thongTinOptitonNew.capturedPieces[quanCoDaDauTu % 4] = thongTinOptitonNew.capturedPieces[quanCoDaDauTu % 4].filter(
                                    number => number !== quanCoDaDauTu
                                );
                            }
                        }
                        return thongTinOptitonNew;
                    });

                    setBoardMatrix(convertToNameMatrix(result.boardMatrix));
                    setLuotCuaToiFunc(false);
                    setIdQuanCoDauTu(null);
                }
                setNotifyFunc(result.notify);
            } catch (error) {
                console.error(error);
            }
        }
    }
    const quyetDinhKhongDiChuyen = () => {
        setNotifyFunc("Bạn đã thực hiện quyết định không di chuyển, giờ là lượt của máy.");
        setLuotCuaToiFunc(false);
    }

    const phongTenLua = async () => {
        // Chuyển ma trận thành dạng số nguyên trước khi chuyển đi
        const integerMatrix = boardMatrix.map(row => row.map(item => item.number));
        if (nuocCo.dauTenLua.name !== "" && nuocCo.duoiTenLua.name !== "" && nuocCo.oBenDap.name !== "") {
            let diChuyenAnQuanState = true;
            if (getConfirmStateFunc()) {
                diChuyenAnQuanState = window.confirm(`Bạn muốn thực hiện di chuyển ăn quân đến ô ${nuocCo.oBenDap.name}`)
            }
            if (diChuyenAnQuanState === true) {
                const dataSend = { matrix: integerMatrix, data: nuocCo };
                try {
                    const result = await fetchData(getAPI(api.di_chuyen_an_quan), methods.post, dataSend);
                    if (result.state === true) {
                        setBoardMatrix(convertToNameMatrix(result.boardMatrix));
                        setDiemSofunc(diemTruoc => {
                            const { diemToi, doiThu } = diemTruoc;
                            return { diemToi: diemToi + result.score, doiThu: doiThu };
                        });
                        setCoMoiAn(result.listDeleteEnable);
                        setLoaiViTri(3);
                        setThongTinOption(prev => {
                            const thongTinOptitonNew = { ...prev };
                            for (let chessInList of result.listChess) {
                                const index = chessInList % 4;

                                // Kiểm tra và tạo mảng nếu cần
                                if (!thongTinOptitonNew.capturedPieces[index]) {
                                    thongTinOptitonNew.capturedPieces[index] = [];
                                }

                                // Sử dụng Set để tránh trùng lặp
                                const capturedPiecesSet = new Set(thongTinOptitonNew.capturedPieces[index]);

                                if (!capturedPiecesSet.has(chessInList)) {
                                    capturedPiecesSet.add(chessInList);
                                    thongTinOptitonNew.capturedPieces[index] = [...capturedPiecesSet];
                                }
                            }

                            return thongTinOptitonNew;
                        })
                    }
                    setNotifyFunc(result.notify);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        else if (nuocCo.oBenDap.name !== "") {
            let diChuyenKhongAnQuanState = true;
            if (getConfirmStateFunc()) {
                diChuyenKhongAnQuanState = window.confirm(`Bạn muốn thực hiện di chuyển không ăn quân đến ô ${nuocCo.oBenDap.name}`);
            }
            if (diChuyenKhongAnQuanState === true) {
                const dataSend = { matrix: integerMatrix, data: nuocCo };
                try {
                    const result = await fetchData(getAPI(api.di_chuyen_khong_an_quan), methods.post, dataSend);
                    if (result.state === true) {
                        setBoardMatrix(convertToNameMatrix(result.boardMatrix));
                        setLuotCuaToiFunc(false);
                    }
                    setNotifyFunc(result.notify);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        resetNuocCo();
    }
    // ================================= Đoạn code của người =================================================

    // ================================= Đoạn code của máy =================================================
    // Đoạn này hiện tại chỉ có ăn quân xuống bên dưới
    const mayAnQuan = (row, col) => {
        // Hiện tại chỉ có chức năng ăn xuống dưới, đồng nghĩa với việc chỉ có thể ăn khi nó ở từ hàng 3 trở xuống (0,1,2,3)
        // di chuyển không ăn quân
        if (row > 3) {
            return false;
        }
        const quanAn = [];
        const toaDoQuanAn = [];
        let timBenDap = false;

        for (let i = row + 1; i <= 7; i++) {
            // Tìm bến đáp = false tức là đang trong chế độ tìm quân ăn
            // nếu tìm được đủ tổ hợp bộ 3 tức là đã ăn đủ quân, giờ tìm 1 bến đáp là oke
            let quanCoMoi = boardMatrix[i][col].number;
            if (timBenDap === false) {
                // Trường hợp trong tổ hợp lại có quân doanh nhân đối thủ (chủ động) thì không được ăn
                if (quanCoMoi === 1){
                    return false;
                }
                // Kiểm tra xem số mới thêm vào có số dư chia cho 4 không trùng với tất cả các số trong mảng quanAn chia dư cho 4
                if (quanCoMoi !== null) {
                    let isDupe = quanAn.some(function (value) {
                        return quanCoMoi % 4 === value % 4;
                    });
                    // Nếu trùng, return false vì không ăn được
                    if (isDupe) {
                        // console.log("Trùng quân cờ");
                        return false;
                    }
                    quanAn.push(quanCoMoi);
                    toaDoQuanAn.push(i);
                    if (quanAn.length >= 3) {
                        timBenDap = true;
                    }
                }
            }
            else {
                // Trường hợp đã có tổ hợp, mà trên đường đi tìm bến đáp lại tìm thấy quân doanh nhân chủ động thì không đi được.
                if (quanCoMoi === 1){
                    return false;
                }
                if (quanCoMoi === null) {
                    // Mất 0.8s để tìm bến đáp
                    setTimeout(() => {
                        setNuocCo({
                            duoiTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            dauTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            oBenDap: {
                                x: null,
                                y: null,
                                name: boardMatrix[i][col].name
                            }
                        });
                    }, 800);

                    // mất thêm 0.8s để tìm đuôi tên lửa
                    setTimeout(() => {
                        setNuocCo({
                            duoiTenLua: {
                                x: toaDoQuanAn[0],
                                y: col,
                                name: boardMatrix[toaDoQuanAn[0]][col].name
                            },
                            dauTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            oBenDap: {
                                x: null,
                                y: null,
                                name: boardMatrix[i][col].name
                            }
                        });
                    }, 1600);

                    // set màu cho đuôi, đầu, bến đáp
                    setTimeout(() => {
                        setNuocCo({
                            duoiTenLua: {
                                x: toaDoQuanAn[0],
                                y: col,
                                name: boardMatrix[toaDoQuanAn[0]][col].name
                            },
                            dauTenLua: {
                                x: null,
                                y: null,
                                name: boardMatrix[toaDoQuanAn[toaDoQuanAn.length - 1]][col].name
                            },
                            oBenDap: {
                                x: null,
                                y: null,
                                name: boardMatrix[i][col].name
                            }
                        });
                    }, 2400);

                    // Đây là bôi vàng cho quân cờ ăn và di chuyển quân doanh nhân
                    setTimeout(() => {
                        const updatedMatrix = [...boardMatrix];
                        updatedMatrix[row][col].number = null;
                        updatedMatrix[i][col].number = 0;

                        // Đoạn này là tắt tất cả ô vàng đi trước
                        for (let i = 0; i <= 7; i++) {
                            for (let j = 0; j <= 7; j++) {
                                updatedMatrix[i][j].isMarker = false;
                            }
                        }
                        for (let row1 of toaDoQuanAn) {
                            updatedMatrix[row1][col].isMarker = true;
                        }
                        setBoardMatrix(updatedMatrix);
                        resetNuocCo();
                    }, 3200);

                    // Sau cùng là làm mất các quân đã ăn
                    setTimeout(() => {
                        const updatedMatrix = [...boardMatrix];
                        // Đoạn này là xóa đi các quân đã ăn
                        for (let row1 of toaDoQuanAn) {
                            updatedMatrix[row1][col].isMarker = false;
                            updatedMatrix[row1][col].number = null;
                        }
                        setBoardMatrix(updatedMatrix);
                        setDiemSofunc(diemTruoc => {
                            const { diemToi, doiThu } = diemTruoc;
                            return { diemToi: diemToi, doiThu: doiThu + quanAn.length };
                        })
                        setNotifyFunc("Máy tính đã thực hiện di chuyển ăn quân, giờ là lượt chơi của bạn.");
                        setLuotCuaToiFunc(true);
                    }, 6000);

                    return true;
                }
            }
        }
        // Nếu ăn được thì trong vòng for đã return true rồi, nên nếu trong đó không có gì thì cuối cùng return false là chuẩn
        return false;
    }

    const randomDiChuyen = (row, col) => {
        // Kiểm tra và trả về 4 ô liền kề nếu thỏa mãn
        for (let i = 0; i < row; i++) {
            if (boardMatrix[i][col].number !== null) {
                break;
            }
            if (i === (row - 1)) {
                return { row: 0, col: col };
            }
        }
        const adjacentPositions = [];
        if (row - 1 >= 0 && row - 1 <= 7) {
            adjacentPositions.push({ row: row - 1, col });
        }
        if (row + 1 <= 7 && row + 1 <= 7) {
            adjacentPositions.push({ row: row + 1, col });
        }
        if (col - 1 >= 0 && col - 1 <= 7) {
            adjacentPositions.push({ row, col: col - 1 });
        }
        if (col + 1 <= 7 && col + 1 <= 7) {
            adjacentPositions.push({ row, col: col + 1 });
        }

        // Lọc các vị trí liền kề thỏa mãn và trả về một vị trí ngẫu nhiên
        const validAdjacentPositions = adjacentPositions.filter(pos => boardMatrix[pos.row][pos.col].number === null);
        if (validAdjacentPositions.length > 0) {
            const randomAdjacentPosition = validAdjacentPositions[Math.floor(Math.random() * validAdjacentPositions.length)];
            return randomAdjacentPosition;
        }

        // Nếu không có vị trí liền kề thỏa mãn, trả về null
        return null;
    }

    const mayDiChuyKhongAnQuan = (row, col) => {
        const randomviTri = randomDiChuyen(row, col);

        if (randomviTri === null) {
            setTimeout(() => {
                setNotifyFunc("Máy tính đã thực hiện quyết định không di chuyển, giờ là lượt của bạn.");
                setLuotCuaToiFunc(true);
            }, 4000);
            return;
        }
        const newRow = randomviTri.row;
        const newCol = randomviTri.col;

        setNuocCo({
            duoiTenLua: {
                x: null,
                y: null,
                name: ""
            },
            dauTenLua: {
                x: null,
                y: null,
                name: ""
            },
            oBenDap: {
                x: newRow,
                y: newCol,
                name: boardMatrix[newRow][newCol].name
            }
        });
        setTimeout(() => {
            const updatedMatrix = [...boardMatrix];
            // Đoạn này là tắt tất cả ô vàng đi trước
            for (let i = 0; i <= 7; i++) {
                for (let j = 0; j <= 7; j++) {
                    updatedMatrix[i][j].isMarker = false;
                }
            }
            updatedMatrix[row][col].number = null;
            updatedMatrix[newRow][newCol].number = 0;
            setBoardMatrix(updatedMatrix);
            resetNuocCo();
            setNotifyFunc("Máy tính đã thực hiện di chuyển không ăn quân, giờ là lượt của bạn.")
            setLuotCuaToiFunc(true);
        }, 2000);

    }

    // Cái này phục vụ mục đích là khi người chơi chọn lượt chơi xong
    // Thì sẽ đến máy chơi, tức luotcuatoi = false
    useEffect(() => {
        if (luotCuaToi === false) {
            // giả dụ cho máy tính chơi sau 2 giây
            setTimeout(() => {
                let zeroPosition = null;

                // Tìm vị trí số 0 trong ma trận
                for (let i = 0; i < 8; i++) {
                    for (let j = 0; j < 8; j++) {
                        if (boardMatrix[i][j].number === 0) {
                            zeroPosition = { row: i, col: j };
                            break;
                        }
                    }
                    if (zeroPosition) {
                        break;
                    }
                }

                // Nếu không có số 0, trả về null
                if (zeroPosition === null) {
                    // console.log("ko tìm thấy doanh nhân thụ động");
                    return;
                }

                const { row, col } = zeroPosition;

                // Kiểm tra nếu máy không ăn được quân thì di chuyển random
                if (mayAnQuan(row, col) === false) {
                    mayDiChuyKhongAnQuan(row, col);
                }
            }, 600);
        }
    }, [luotCuaToi]);

    useEffect(() => {
        if (diemSo.diemToi + diemSo.doiThu === 30) {
            roiPhong();
        }
    }, [diemSo])
    // ================================= Đoạn code của máy =================================================

    const resetNuocCo = () => {
        // Bước này để reset nước cờ mỗi lần nhận nước đi xon
        setNuocCo({
            duoiTenLua: {
                x: null,
                y: null,
                name: ""
            },
            dauTenLua: {
                x: null,
                y: null,
                name: ""
            },
            oBenDap: {
                x: null,
                y: null,
                name: ""
            }
        })
    }

    const tatXemCo = () => {
        setQuanCoBamVao({ ...quanCoBamVao, idQuanCo: null });
        if (coMoiAn.length <= 0) {
            const updatedMatrix = [...boardMatrix];
            for (let i = 0; i <= 7; i++) {
                for (let j = 0; j <= 7; j++) {
                    updatedMatrix[i][j].isMarker = false;
                }
            }
            setLoaiViTri(0);
            setLuotCuaToiFunc(false);
            setNotifyFunc("Bạn đã hoàn thành bấm vào các quân cờ, giờ là lượt chơi của máy tính.");
            setBoardMatrix(updatedMatrix);
        }
    }

    useEffect(() => {
        let idTimeInterval;
        const clearTimeOut = () => {
            if (idTimeInterval) {
                clearInterval(idTimeInterval);
            }
        };
        setGameTimeOut(5);
        if (oanTuTiState) {
            setLuotCuaToiFunc(null);
            // đặt timeout là 10s, dặt lượt chơi là null để thời gian header không chạy
            idTimeInterval = setInterval(() => {
                setGameTimeOut(prev => {
                    if (prev > 0) {
                        return (prev - 1);
                    }
                    clearTimeOut();
                    setOanTuTiState(false);
                    return 0;
                });
            }, 1000);
        }
        else if (endGameState) {
            setLuotCuaToiFunc(null);
            idTimeInterval = setInterval(() => {
                setGameTimeOut(prev => {
                    if (prev > 0) {
                        return (prev - 1);
                    }
                    clearTimeOut();
                    return 0;
                });
            }, 1000);
        }
        else if (oanTuTiState === false) {
            // Tùy ý chọn ra lượt chơi, nếu true thì là lượt của tôi, false là lượt của máy
            const luotChoingauNhien = () => (Math.random() < 0.5);
            setLuotCuaToiFunc(!luotChoingauNhien);
            const randomNotify = luotChoingauNhien ? "Bạn đã thắng oẳn tù tì, giờ là lượt chơi của máy." : "Bạn đã thua oẳn tù tì, giờ là lượt chơi của bạn.";
            setNotifyFunc(randomNotify);
        }
        return () => {
            clearTimeOut();
        }
    }, [oanTuTiState, endGameState])

    const oanTuTi = (type, value) => {
        if (type === 0) {
            setOanTuTiState(true);
        }
        // if (type === 1) {
        //     const nuocCoGuiDi = {
        //         move: value
        //     }
        // }
    }

    const roiPhong = () => {
        setEndResult([{ name: "Bạn", score: diemSo.diemToi }, { name: "Robot", score: diemSo.doiThu }]);
        setEndGameState(true);
    }

    const choiLai = () => {
        nav('/');
    }
    return (
        <Fragment>

            <div style={{ width: minWidth, height: minWidth }} className={style.NPCPlayboard}>
                <ImageBoard
                    boardMatrix={boardMatrix}
                    nuocCo={nuocCo}
                    bamVaoOCoFunc={bamVaoOCo}
                    bamVaoQuanCoFunc={bamVaoQuanCo}
                    gocBanCo={0}
                    isDisable={!luotCuaToi}
                    chessman={thongTinOption.chessman}
                />
                <Chessman
                    turnState={true}
                    clientId={luotCuaToi}
                    tatXemCoFunc={tatXemCo}
                    gocBanCo={0}
                    quanCoBamVao={quanCoBamVao}
                    boardSize={minWidth}
                />
            </div>
            <OptionsBox
                doiGocBanCoFunc={() => console.log("Xoay bàn cờ.")}
                idQuanCoDauTu={idQuanCoDauTu}
                setIdQuanCoDauTuFunc={setIdQuanCoDauTu}
                roiPhongFunc={roiPhong}
                oanTuTiFunc={oanTuTi}
                danhSachQuanCo={thongTinOption.capturedPieces}
                danhSachCacChucNangDisable={[]}
            />
            <OanTuTi visible={oanTuTiState} oanTuTiFunc={oanTuTi} timeout={gameTimeout} />
            <EndGame visible={endGameState} choiLaifunc={choiLai} endResult={endResult} timeout={gameTimeout} />
        </Fragment>
    )
}

export default NPCPlayboard;
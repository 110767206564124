import { BrowserRouter, Route, Routes } from "react-router-dom";

import DefaultLayout from './base/layout/DefaultLayout';

import { publicRoutes } from "./constants/routes";
import { Helmet } from 'react-helmet';
// import { useEffect } from "react";
import { ban_co_full, favicon } from './assets/assets';

function App() {
  // useEffect(()=>{
  //   document.title = "Trò chơi chữ Việt";
  // }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Helmet>
          <title>Trò chơi chữ Việt</title>
          <link rel="icon" href={favicon} />
          <meta name="description" content="Cờ doanh nhân - Cờ chữ Việt - Cờ chữ Thành Công!" />
          <meta property="og:title" content="Trò chơi chữ Việt" />
          <meta property="og:description" content="Cờ doanh nhân - Cờ chữ Việt - Cờ chữ Thành Công!" />
          <meta property="og:image" content={ban_co_full} />
          <link rel="canonical" href="URL of the canonical page" />
        </Helmet>
        <Routes>
          {
            publicRoutes.map((route, index) => {
              let Layout = DefaultLayout;

              if (route.layout) {
                Layout = route.layout
              };
              const Page = route.component;
              return <Route key={index} path={route.path} element={<Layout><Page /></Layout>} />
            })
          }
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

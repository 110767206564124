import EmptyLayout from '../base/layout/EmptyLayout';

import HomePage from "../pages/HomePage";
import RoomPage from "../pages/RoomPage";
import ViewPage from "../pages/ViewPage";
import NotFoundPage from '../pages/NotFoundPage';
import RulePlayPage from '../pages/RulePlayPage';
import PlayWithRobotPage from '../pages/PlayWithRobotPage';

import { AiFillHome, AiOutlineHistory, AiTwotoneTrophy, AiOutlineUsergroupAdd, AiFillRobot, AiOutlinePlusCircle } from "react-icons/ai";
import { GiRuleBook } from "react-icons/gi";

const publicRoutes = [
    { path: '/', component: HomePage, layout: null },
    { path: '/luật-chơi', component: RulePlayPage, layout: null },
    { path: '/Chơi-với-robot', component: PlayWithRobotPage, layout: EmptyLayout },
    { path: '/room/:idRoom', component: RoomPage, layout: EmptyLayout },
    { path: '/view/:idRoom', component: ViewPage, layout: EmptyLayout },
    { path: '*', component: NotFoundPage, layout: null },
];

const privateRoutes = [

];

const publicLeftSidebarUrl = [
    [
        {
            icon: <AiFillHome />,
            title: "Trang chủ",
            url: "/"
        },
        {
            icon: <AiOutlineHistory />,
            title: "Lịch sử trận đấu",
            url: "#"
        },
        {
            icon: <AiTwotoneTrophy />,
            title: "Giải đấu của tôi",
            url: "#"
        },
    ],
    [
        {
            icon: <AiOutlineUsergroupAdd />,
            title: "Chơi với một người bạn",
            url: "#"
        },
        {
            icon: <AiFillRobot />,
            title: "Chơi với robot",
            url: "/chơi-với-robot"
        },
        {
            icon: <GiRuleBook />,
            title: "Luật chơi",
            url: "/luật-chơi"
        },
        {
            icon: <AiOutlinePlusCircle />,
            title: "Tạo giải đấu",
            url: "#"
        },
    ]
]
export { publicRoutes, privateRoutes, publicLeftSidebarUrl };
import style from './InvestBox.module.scss';

import { Fragment, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import { images } from '../../assets/assets';

function InvestBox({idQuanCoDauTu, setIdQuanCoDauTuFunc, danhSachQuanCo, isDiable}) {
    const [investBoxState, setInvestBoxState] = useState(false);
    // const danhSachQuanCo = [
    //     [4, 4, 4, 4, 4, 4, 4],
    //     [5],
    //     [6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
    //     [7, 7, 7, 7, 7]
    // ]

    // Hàm này thực hiện chức năng đầu tư quân cờ bằng cách nhận vào số hiệu của quân cờ (id quân cờ). 
    const chonDautu = (idQuanCo) => {
        setIdQuanCoDauTuFunc(idQuanCo);
        setInvestBoxState(false);
    }
    return (
        <Fragment>
            <div style={{ display: `${investBoxState ? 'block' : 'none'}` }} className={style.InvestBox}>

                <div className={style.header}>
                    <div>
                        <button onClick={() => {
                            setIdQuanCoDauTuFunc(null);
                            setInvestBoxState(false);
                        }} className={style.bo_chon_dau_tu_btn}>Bỏ chọn đầu tư</button>
                    </div>

                    <span onClick={() => { setInvestBoxState(false); }}><AiOutlineClose /></span>
                </div>

                <div className={style.body}>
                    <ul>
                        {
                            danhSachQuanCo.map((row, indexRow) =>
                            (<li key={indexRow} className={style.loai_quan_co}>
                                {
                                    <ul className={style.danh_sach_quan_da_an}>
                                        {
                                            row.length > 0
                                                ?
                                                row.map((idQuanCo, indexCol) => (
                                                    <li
                                                        key={indexCol}
                                                        className={style.quan_da_dan}
                                                        onClick={() => chonDautu(idQuanCo)}
                                                    >
                                                        <div className={style.anh_quan_co} style={{ backgroundImage: `url(${images[idQuanCo].image})` }} />
                                                    </li>
                                                ))
                                                :
                                                <li className={style.quan_trang}></li>
                                        }
                                    </ul>
                                }
                            </li>))
                        }
                    </ul>
                </div>
            </div>


            {/* <div style={{ display: `${investBoxState ? 'none' : 'flex'}` }} onClick={() => setInvestBoxState(true)} className={`${style.InvestBox_hiden} ${isDiable && "btn-disabled"}`}> */}
            <div style={{ display: `${investBoxState ? 'none' : 'flex'}` }} onClick={() => setInvestBoxState(true)} className={`${style.InvestBox_hiden}`}>
                <div style={{ display: idQuanCoDauTu !== null ? 'flex' : 'none' }}>
                    <span className={style.ten_quan_co_dau_tu}>Bạn đã chọn: {idQuanCoDauTu && images[idQuanCoDauTu].name}</span>
                    <div className={style.quan_co_dau_tu} style={{ backgroundImage: `url('${idQuanCoDauTu && images[idQuanCoDauTu].image}')` }} />
                </div>

                <span style={{ display: idQuanCoDauTu !== null ? 'none' : 'block' }}>Chọn đầu tư</span>
            </div>
        </Fragment>
    )
};

export default InvestBox;
import { v4 as uuidv4 } from 'uuid';

// Hàm biến giây thành định dạng 00:00
function secondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  return formattedTime;
}

async function fetchData(api, method, data) {
  try {
    const response = await fetch(api, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.state === true) {
      return result.result;
    } else {
      throw result.notify;
    }
  } catch (error) {
    throw error; // Đảm bảo xử lý lỗi và trả về một Promise bị từ chối.
  }
}

// Hàm để lấy clientId từ localStorage hoặc tạo mới nếu không tồn tại
function getClientId() {
  // Kiểm tra xem có clientId trong localStorage không
  const storedClientId = localStorage.getItem('clientId');
  if (storedClientId) {
    return storedClientId;
  }
  // Nếu không có clientId trong localStorage, tạo mới và lưu vào
  const newClientId = uuidv4();
  localStorage.setItem('clientId', newClientId);
  return newClientId;
};

// Hàm để xóa clientId khỏi localStorage
function removeClientId() {
  localStorage.removeItem('clientId');
};

function getConfirmStateFunc() {
  const confirmState = localStorage.getItem('confirmState');
  if (confirmState) {
    if (confirmState === 'true') {
      return true;
    }
    return false;
  }
  localStorage.setItem('confirmState', 'true');
  return true;
}

function setConfirmStateFunc(state) {
  localStorage.setItem('confirmState', state + '');
}

function getPlayerInfo() {
  // Kiểm tra xem có clientId trong localStorage không
  const storedInfo = localStorage.getItem('info');
  if (storedInfo) {
    return JSON.parse(storedInfo);
  }
  const newPlayerInfo = { name: "", score: 1000 }
  localStorage.setItem("info", JSON.stringify(newPlayerInfo));
  return newPlayerInfo;
}

function removePlayerInfo() {
  localStorage.removeItem("info");
}

function setPlayerName(name) {
  const playerInfo = getPlayerInfo();
  playerInfo.name = name;
  localStorage.setItem('info', JSON.stringify(playerInfo));
}

function setPlayerScore(score) {
  const playerInfo = getPlayerInfo();
  playerInfo.score = score;
  localStorage.setItem('info', JSON.stringify(playerInfo));
}



export {
  secondsToMinutes,
  fetchData,
  getClientId,
  removeClientId,
  getConfirmStateFunc,
  setConfirmStateFunc,
  getPlayerInfo,
  setPlayerName,
  setPlayerScore,
  removePlayerInfo
}; 
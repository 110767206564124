import LeftSidebar from '../left_sidebar/LeftSidebar';
import { Fragment } from 'react';
import style from './DelaultLayout.module.scss';
function DefaultLayout({ children }) {

    return (
        <Fragment>
            <LeftSidebar />
            <div className={style.DefaultLayout}>
                {children}
            </div>
        </Fragment>
    )
};

export default DefaultLayout;
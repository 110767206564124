import React, { useState, useEffect, useRef, Fragment, memo } from "react";

import { AiOutlineClose, AiOutlineMessage } from "react-icons/ai";

import { getAPI, get_socket_chat } from "../../constants/api";
import style from './ChatBox.module.scss';

import { api } from "../../constants/api";
import { getPlayerInfo } from "../../constants/services";
import { useNavigate } from "react-router-dom";

function ChatBox({ roomId, clientId, setThongTinHeaderFunc, setTimesFunc }) {
    const [mess, setMess] = useState([]);
    const nav = useNavigate();
    const [message, setMessage] = useState('');
    const [messBoxState, setMessBoxState] = useState(false);
    // biến này đếm số lượng tin nhắn mới được gửi đến khi mà hộp tin nhắn bị đóng
    const [newMessCount, setNewMessCount] = useState(0);

    const wsRef = useRef(null);
    // Tham chiếu đến phần tử cuối cùng trong danh sách tin nhắn
    const messagesEndRef = useRef(null);
    useEffect(() => {
        const { name, score } = getPlayerInfo();
        if (roomId && clientId && name && score) {
            // Tạo kết nối WebSocket khi component được mount
            const socket = new WebSocket(get_socket_chat(roomId, clientId, name, score));

            // Lắng nghe sự kiện khi kết nối mở
            socket.onopen = () => {
                console.log('Kết nối socket chat.');
            };

            // Lắng nghe sự kiện khi nhận được dữ liệu từ server
            socket.onmessage = (data) => {
                try {
                    const mess_on_data = JSON.parse(data.data);
                    if (mess_on_data.type === "play_time") {
                        setTimesFunc(mess_on_data.times)
                    } else if (mess_on_data.type === "message") {
                        setMess(oldMsgs => [...oldMsgs, mess_on_data]);
                        setNewMessCount(prevCount => prevCount + 1);
                        scrollToBottom();
                    } else if (mess_on_data.type === "config") {
                        const content = mess_on_data.content;
                        setThongTinHeaderFunc(prevData => {
                            const newData = { ...prevData };
                            for (let dataItem of content) {
                                if (dataItem.id === clientId) {
                                    newData.ten_toi = dataItem.name;
                                    newData.anh_cua_toi = getAPI(api.get_image) + "/" + dataItem.avatar;
                                } else {
                                    newData.ten_doi_thu = dataItem.name;
                                    newData.anh_doi_thu = getAPI(api.get_image) + "/" + dataItem.avatar;
                                }
                            }
                            return newData;
                        })
                    }
                }
                catch (exception) {
                    console.log("Ngoại lệ: " + exception);
                    // console.log(data);
                }
            };

            // Lắng nghe sự kiện khi có lỗi xảy ra
            socket.onerror = (error) => {
                nav("/");
                console.error('WebSocket error:', error);
            };

            // Lắng nghe sự kiện khi kết nối đóng
            socket.onclose = () => {
                console.log('Ngắt kết nối chat socket');
            };

            // Lưu kết nối WebSocket vào state
            wsRef.current = socket;
        } else {
            nav("/");
        }
        // Cleanup: Đóng kết nối khi component unmount
        return () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
            }
        };
    }, [roomId, clientId]);

    useEffect(() => {
        // Cuộn xuống cuối cùng mỗi khi danh sách tin nhắn thay đổi
        scrollToBottom();
    }, [mess]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const sendMessage = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            if (message !== null && message !== '') {
                const msg = {
                    id: clientId,
                    content: message,
                    type: "message"
                }
                wsRef.current.send(JSON.stringify(msg));
                setMessage('');
            }
        } else {
            console.log('WebSocket không mở hoặc đã bị đóng lại.');
            // Xử lý hoặc thông báo lỗi tại đây nếu cần thiết
        }
    }

    const handleChange = (e) => {
        setMessage(e.target.value);
    }

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendMessage();
        }
    }
    return (
        <Fragment>
            <div style={{ display: `${messBoxState ? 'block' : 'none'}` }} className={style.ChatBox}>
                <div className={style.header}>
                    <span onClick={() => {
                        setMessBoxState(false);
                        setNewMessCount(0);
                    }}><AiOutlineClose /></span>
                </div>
                <div className={style.box_chat_message}>
                    {
                        mess.map((m, index) =>
                        (<div key={index} className={`${m.id === clientId ? style.your_message : style.other_people}`}>
                            <span>{m.content}</span>
                        </div>
                        ))}
                    {/* Tham chiếu đến phần tử cuối cùng */}
                    <div ref={messagesEndRef} />
                </div>

                <div className={style.send_box}>
                    <textarea
                        className={style.message_input}
                        value={message}
                        onKeyDown={onEnterPress}
                        onChange={handleChange}
                        placeholder="Nhập tin nhắn ..."
                    />
                    <button className={style.submit_btn} onClick={sendMessage}>Send</button>
                </div>

            </div>

            <div style={{ display: `${messBoxState ? 'none' : 'flex'}` }} onClick={() => setMessBoxState(true)} className={style.ChatBox_hiden}>
                <span className={style.mess_icon}>
                    <AiOutlineMessage />
                </span>

                {
                    newMessCount && !messBoxState
                        ?
                        <span className={style.mess_count}>
                            {newMessCount < 10 ? newMessCount : "10..."}
                        </span>
                        : <Fragment />
                }
            </div>
        </Fragment>
    )
};

export default memo(ChatBox);
function NotFoundPage() {

    return (
        <div>
            <h2>404 - Not Found</h2>
            <h2>Trang bạn đang tìm kiếm không tồn tại.</h2>
        </div>
    );
}

export default NotFoundPage;
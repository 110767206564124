import { Fragment, useEffect, useState } from 'react';
import style from './RoomHeader.module.scss';
import { AiFillCloseCircle } from "react-icons/ai";
import { secondsToMinutes } from '../../constants/services';

function RoomHeader({ ten_toi = "Tôi", anh_cua_toi, ten_doi_thu = "Đối thủ", anh_doi_thu, diem_toi, diem_doi_thu, thong_bao, clientId, times }) {
    const [notifyState, setNotifyState] = useState(false);
    const myTurn = true;
    let thoiGianCuaToi, thoiGianDoiThu;
    if (times[0].id === clientId) {
        thoiGianCuaToi = times[0].time;
        thoiGianDoiThu = times[1].time;
    }
    else {
        thoiGianCuaToi = times[1].time;
        thoiGianDoiThu = times[0].time;
    }

    useEffect(() => {
        let timeoutId = null;

        if (thong_bao !== null && thong_bao !== undefined && thong_bao !== "") {
            setNotifyState(true);
            timeoutId = setTimeout(() => {
                setNotifyState(false)
            }, 10000);
        }
        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        }
    }, [thong_bao])
    return (
        <Fragment>
            <div className={style.RoomHeader}>
                <div className={style.player}>
                    <div className={style.score}>
                        <span>Điểm</span>
                        <span >{diem_toi}</span>
                    </div>
                    <div className={style.infomation}>
                        <span className={style.name}>{ten_toi}</span>
                        <span className={style.time}>{secondsToMinutes(thoiGianCuaToi)}</span>
                    </div>
                    <div style={{ backgroundImage: `url('${anh_cua_toi}')` }} className={`${style.avatar} ${myTurn && style.avatar_border}`}></div>
                </div>

                <div className={style.opponent}>
                    <div style={{ backgroundImage: `url('${anh_doi_thu}')` }} className={`${style.avatar} ${!myTurn && style.avatar_border}`}></div>
                    <div className={style.infomation}>
                        <span className={style.name}>{ten_doi_thu}</span>
                        <span className={style.time}>{secondsToMinutes(thoiGianDoiThu)}</span>
                    </div>
                    <div className={style.score}>
                        <span>Điểm</span>
                        <span >{diem_doi_thu}</span>
                    </div>
                </div>
            </div>

            <div
                style={{ transform: `translate(-50%, ${notifyState ? 8 : -20}rem)` }}
                className={style.notify}>
                <p>{thong_bao}</p>
                <span onClick={() => setNotifyState(false)}><AiFillCloseCircle /></span>
            </div>
        </Fragment>
    )
};

export default RoomHeader;
import { Fragment, useEffect, useRef, useState, memo } from 'react';
import style from './ViewBoard.module.scss';

import { useWindowSize } from '../../hooks/useWindowSize';

import ImageBoard from '../../base/image_board/ImageBoard';
import Chessman from '../chessman/Chessman';

import { get_socket_view } from '../../constants/api';
import convertToNameMatrix from '../../constants/matrix';
import { useNavigate } from 'react-router-dom';
import { initMatrix } from '../../constants/matrix';
import EndGame from '../end_game/EndGame';

function ViewBoard({ roomId, setThongBaoFunc, capNhatDiemFunc, setTimesFunc, capNhatThongTinFunc }) {
    const navigate = useNavigate();
    const [thongTinOption, setThongTinOption] = useState({
        notify: "",
        turnState: null,
        winSate: null,
        boardMatrix: initMatrix
    });
    const [quanCoBamVao, setQuanCoBamVao] = useState({ idQuanCo: null, row: null, col: null }); // Đây là giá trị khi bấm vào quân cờ sẽ có, phục vụ di chuyển ăn quân
    const [nuocCo, setNuocCo] = useState({
        duoiTenLua: {
            x: null,
            y: null,
            name: ""
        },
        dauTenLua: {
            x: null,
            y: null,
            name: ""
        },
        oBenDap: {
            x: null,
            y: null,
            name: ""
        }
    });
    // const [endResult, setEndResult] = useState([]);
    const [gameTimeout, setGameTimeOut] = useState(0);
    const [endGameState, setEndGameState] = useState(false);

    const [width, height] = useWindowSize();
    const minWidth = width >= 740 ? '600px' : `${Math.min(width, height)}px`;
    const wsRef = useRef(null);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (roomId) {
                // Tạo kết nối WebSocket khi component được mount
                const socket = new WebSocket(get_socket_view(roomId));

                // Lắng nghe sự kiện khi kết nối mở
                socket.onopen = () => {
                    console.log('Kết nối đến socket xem');
                };

                // Lắng nghe sự kiện khi nhận được dữ liệu từ server
                socket.onmessage = (data) => {
                    try {
                        const mess_on_data = JSON.parse(data.data);

                        switch (mess_on_data.type) {
                            case "play_time":
                                setTimesFunc(mess_on_data.times)
                                break;

                            case "time":
                                setGameTimeOut(mess_on_data.time);
                                break;

                            case "dat_quan_doanh_nhan":
                                const move1 = mess_on_data.move;
                                setThongTinOption(prev => {
                                    const newDataOption = { ...prev };
                                    newDataOption.boardMatrix = convertToNameMatrix(move1.boardMatrix);
                                    newDataOption.turnState = move1.turnState;
                                    return newDataOption
                                });
                                setThongBaoFunc(move1.notify);
                                break;

                            case "choi_co":
                                const move2 = mess_on_data.move;
                                setThongTinOption(prev => {
                                    const newDataOption = { ...prev };
                                    newDataOption.boardMatrix = convertToNameMatrix(move2.boardMatrix);
                                    newDataOption.turnState = move2.turnState;
                                    return newDataOption
                                });

                                // Đoạn này trả về quân cờ sẽ show ra cho người xem (quân cờ phóng to)
                                setQuanCoBamVao(prev => {
                                    const quanCoBamVaoNew = { ...prev };
                                    const viewChess = move2.viewChess;
                                    quanCoBamVaoNew.idQuanCo = viewChess.chess;
                                    quanCoBamVaoNew.row = viewChess.row;
                                    quanCoBamVaoNew.col = viewChess.col;
                                    return quanCoBamVaoNew;
                                })
                                capNhatDiemFunc(move2.scores);
                                resetNuocCo();

                                // =================== 
                                setThongBaoFunc(move2.notify);
                                break;

                            case "roi_phong":
                                navigate("/")
                                break;

                            case "vi_tri":
                                setNuocCo(mess_on_data.move)
                                break;

                            case "notify":
                                setThongBaoFunc(mess_on_data.notify);
                                break;

                            case "ket_thuc":
                                // setEndResult(mess_on_data.endResult);
                                setEndGameState(true);
                                break;

                            case "config":
                                capNhatThongTinFunc(mess_on_data.content)
                                break;
                            default:
                                console.log("Không rõ là backend gửi cái gì đến frontend ở trong socket game");
                            // console.log(mess_on_data);
                        }
                    }
                    catch (exception) {
                        console.log("Ngoại lệ: " + exception);
                    }
                };

                // Lắng nghe sự kiện khi có lỗi xảy ra
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };

                // Lắng nghe sự kiện khi kết nối đóng
                socket.onclose = () => {
                    console.log('Socket game đóng');
                };

                // Lưu kết nối WebSocket vào state
                wsRef.current = socket;

            }
        }, 1000); // Đợi 1 giây trước khi tạo kết nối

        // Cleanup: Clear timeout nếu component unmount trước khi đã tạo kết nối
        // Cleanup: Đóng kết nối khi component unmount
        return () => {
            clearTimeout(timer);
            // setEndGameState(false);
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
            }
        };
    }, [roomId]);

    const resetNuocCo = () => {
        // Bước này để reset nước cờ mỗi lần nhận nước đi xon
        setNuocCo({
            duoiTenLua: {
                x: null,
                y: null,
                name: ""
            },
            dauTenLua: {
                x: null,
                y: null,
                name: ""
            },
            oBenDap: {
                x: null,
                y: null,
                name: ""
            }
        })
    }

    return (
        <Fragment>
            <div style={{ width: minWidth, height: minWidth, transform: `rotateZ(${0}deg)` }} className={style.ViewBoard}>
                <ImageBoard
                    boardMatrix={thongTinOption.boardMatrix}
                    nuocCo={nuocCo}
                    bamVaoOCoFunc={() => { }}
                    bamVaoQuanCoFunc={() => { }}
                    gocBanCo={0}
                    isDisable={false}
                    chessman={null}
                />

                <Chessman
                    turnState={thongTinOption.turnState}
                    clientId={""}
                    tatXemCoFunc={() => { }}
                    gocBanCo={0}
                    quanCoBamVao={quanCoBamVao}
                    boardSize={minWidth}
                />

                <EndGame choiLaifunc={() => { }} endResult={null} outFunc={() => { }} timeout={gameTimeout} visible={endGameState} />
            </div>
        </Fragment>
    )
}

export default memo(ViewBoard);
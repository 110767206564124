import { Fragment, useEffect, useRef, useState } from 'react';
import style from './NPCRoomHeader.module.scss';
import { AiFillCloseCircle } from "react-icons/ai";
import { secondsToMinutes } from '../../constants/services';
import { anh_robot, ban_co_full } from '../../assets/assets';

function NPCRoomHeader({ diem_toi = 0, diem_doi_thu = 0, thong_bao = "", myTurn = false }) {
    const [notifyState, setNotifyState] = useState(false);
    const [thoiGianCuaToi, setThoiGianCuaToi] = useState(600); // 10 phút
    const [thoiGianRobot, setThoiGianRobot] = useState(600);
    const intervalId = useRef();

    const endGame = () => {
        console.log("Kết thúc game");
    }

    if (thoiGianCuaToi === 0 || thoiGianRobot === 0) {
        clearInterval(intervalId.current);
        endGame();
    }

    const giamThoiGian = () => {
            if (myTurn === true) {
                setThoiGianCuaToi(prevTime => (prevTime > 0 ? prevTime - 1 : prevTime));
            }
            else if (myTurn === false) {
                setThoiGianRobot(prevTime => (prevTime > 0 ? prevTime - 1 : prevTime));
            }
    }
    useEffect(() => {
        // Lượt chơi (myTurn) khi bằng true là tôi, false là robot
        // Vì bản chất component phải render lại thì mới có thời gian mới,
        // Vậy nên đặt đoạn check ở đây là hợp lý
        // Logic cho việc giảm thời gian
        intervalId.current = setInterval(() => {
            giamThoiGian();

        }, 1000);

        return () => clearInterval(intervalId.current);
    }, [myTurn]);

    useEffect(() => {
        let timeoutId = null;

        if (thong_bao !== null && thong_bao !== undefined && thong_bao !== "") {
            setNotifyState(true);
            timeoutId = setTimeout(() => {
                setNotifyState(false)
            }, 10000);
        }
        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        }
    }, [thong_bao])
    return (
        <Fragment>
            <div className={style.NPCRoomHeader}>
                <div className={style.player}>
                    <div className={style.score}>
                        <span>Điểm</span>
                        <span >{diem_toi}</span>
                    </div>
                    <div className={style.infomation}>
                        <span className={style.name}>Bạn</span>
                        <span className={style.time}>{secondsToMinutes(thoiGianCuaToi)}</span>
                    </div>
                    <div style={{ backgroundImage: `url('${ban_co_full}')` }} className={`${style.avatar} ${myTurn && style.avatar_border}`}></div>
                </div>

                <div className={style.opponent}>
                    <div style={{ backgroundImage: `url('${anh_robot}')` }} className={`${style.avatar} ${!myTurn && style.avatar_border}`}></div>
                    <div className={style.infomation}>
                        <span className={style.name}>Robot</span>
                        <span className={style.time}>{secondsToMinutes(thoiGianRobot)}</span>
                    </div>
                    <div className={style.score}>
                        <span>Điểm</span>
                        <span >{diem_doi_thu}</span>
                    </div>
                </div>
            </div>

            <div
                style={{ transform: `translate(-50%, ${notifyState ? 8 : -20}rem)` }}
                className={style.notify}>
                <p>{thong_bao}</p>
                <span onClick={() => setNotifyState(false)}><AiFillCloseCircle /></span>
            </div>
        </Fragment>
    )
};

export default NPCRoomHeader;
import ListRoom from '../components/list_room/ListRoom';
function HomePage() {

    return (
        <div className="HomePage">
           <ListRoom/>
        </div>
    )
};

export default HomePage;
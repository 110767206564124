import { useEffect, useState } from 'react';
import style from './OanTuTi.module.scss';

import { FaHandPaper, FaHammer, FaCut } from "react-icons/fa";
import CenterBox from '../../base/box/CenterBox';

function OanTuTi({ visible, oanTuTiFunc, timeout }) {
    const danhSachLuaChon = [
        { icon: <FaHandPaper />, id: "bao" },  // Bao
        { icon: <FaHammer />, id: "bua" },     // Búa
        { icon: <FaCut />, id: "keo" },        // Kéo
    ];
    const [oanTuTiState, setOanTuTiState] = useState({ id: null, disable: false });

    const luaChonOanTuTi = (idLuaChon) => {
        if (oanTuTiState.disable === true) {
            alert("Bạn không được chọn lại");
        } else {
            setOanTuTiState({ id: idLuaChon, disable: true });
            oanTuTiFunc(1, idLuaChon);
        }
    }

    useEffect(()=>{
        if (visible === true){
            setOanTuTiState({ id: null, disable: false });
        }
    }, [visible]);

    return (
        <CenterBox visible={visible}>
            {/* <h2>Bạn thua, đối thủ ra kéo</h2> */}
            {/* <h2>Bạn thắng, đối thủ ra búa</h2> */}
            {/* <h2>Hòa, mời chọn lại</h2> */}
            <h2>Oẳn tù tì, hãy lựa chọn 1 trong 3 - {timeout}s</h2>
            <div className={style.danh_sach}>
                {
                    danhSachLuaChon.map((value) => (
                        <span
                            key={value.id}
                            style={{ backgroundColor: `${oanTuTiState.id === value.id ? "var(--blue)" : "initial"}` }}
                            className={style.lua_chon}
                            onClick={() => luaChonOanTuTi(value.id)}>
                            {value.icon}
                        </span>
                    ))
                }
            </div>
        </CenterBox>
    )
};

export default OanTuTi;
// import { Fragment } from 'react';

import ViewRoom from '../components/view_room/ViewRoom';

function ViewPage() {

    return (
        <ViewRoom />
    )
};

export default ViewPage;
import { Fragment, useEffect, useState } from 'react';
import style from './RoomHeader.module.scss';
import { AiFillCloseCircle } from "react-icons/ai";
import { secondsToMinutes } from '../../constants/services';
import { images } from '../../assets/assets';

function RoomHeader({ thong_bao, info }) {
    const [notifyState, setNotifyState] = useState(false);
    // const myTurn = true;
    let [player1, player2] = info;

    useEffect(() => {
        let timeoutId = null;

        if (thong_bao !== null && thong_bao !== undefined && thong_bao !== "") {
            setNotifyState(true);
            timeoutId = setTimeout(() => {
                setNotifyState(false)
            }, 10000);
        }
        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        }
    }, [thong_bao])
    return (
        <Fragment>
            <div className={style.RoomHeader}>
                <div className={style.player}>
                    <div className={style.score}>
                        <span>Điểm</span>
                        <span >{player1?.diem}</span>
                    </div>
                    <div className={style.infomation}>
                        <span className={style.name}>{player1?.ten}</span>
                        <span className={style.time}>{secondsToMinutes(player1?.time)}</span>
                    </div>
                    <div style={{ backgroundImage: `url('${images[player1?.id_anh]?.image}')` }} className={`${style.avatar} ${false && style.avatar_border}`}></div>
                </div>

                <div className={style.opponent}>
                    <div style={{ backgroundImage: `url('${images[player2?.id_anh]?.image}')` }} className={`${style.avatar} ${false && style.avatar_border}`}></div>
                    <div className={style.infomation}>
                        <span className={style.name}>{player2?.ten}</span>
                        <span className={style.time}>{secondsToMinutes(player2?.time)}</span>
                    </div>
                    <div className={style.score}>
                        <span>Điểm</span>
                        <span >{player2?.diem}</span>
                    </div>
                </div>
            </div>

            <div
                style={{ transform: `translate(-50%, ${notifyState ? 8 : -20}rem)` }}
                className={style.notify}>
                <p>{thong_bao}</p>
                <span onClick={() => setNotifyState(false)}><AiFillCloseCircle /></span>
            </div>
        </Fragment>
    )
};

export default RoomHeader;
import { Fragment, useEffect, useRef, useState, memo } from 'react';
import style from './PlayBoard.module.scss';

import { useWindowSize } from '../../hooks/useWindowSize';

import ImageBoard from '../../base/image_board/ImageBoard';
import OptionsBox from '../options/OptionsBox';
import EndGame from '../end_game/EndGame';
import OanTuTi from '../oan_tu_ti/OanTuTi';
import Chessman from '../chessman/Chessman';

import { get_socket_game } from '../../constants/api';
import convertToNameMatrix from '../../constants/matrix';
import { useNavigate } from 'react-router-dom';
import { initMatrix } from '../../constants/matrix';
import { getClientId, getConfirmStateFunc, setPlayerScore } from '../../constants/services';

function PlayBoard({ roomId, clientId, setThongBaoFunc, capNhatDiemFunc }) {
    const navigate = useNavigate();
    const [thongTinOption, setThongTinOption] = useState({
        chessman: null,
        notify: "",
        capturedPieces: [[], [], [], []],    // Mảng này chứa các quân cờ sẽ xuất hiện ở trong ô chứa các quân đầu tư
        newcapturedPieces: [], // Mảng này chứa tọa độ những quân cờ mới ăn
        turnState: null,
        winSate: null,
        boardMatrix: initMatrix,
        listOptionDisable: {
            oan_tu_ti: false,
        }
    });
    const [idQuanCoDauTu, setIdQuanCoDauTu] = useState(null);
    const [nuocCo, setNuocCo] = useState({
        duoiTenLua: {
            x: null,
            y: null,
            name: ""
        },
        dauTenLua: {
            x: null,
            y: null,
            name: ""
        },
        oBenDap: {
            x: null,
            y: null,
            name: ""
        }
    });
    /*
    LoaiViTri: 
        + 0: di chuyển ăn quân, không ăn quân
        + 1: đặt quân doanh nhân
        + 3: bấm vào xem quân cờ
    */
    const [loaiViTri, setLoaiViTri] = useState(null); // Loại vị trí dùng để xác nhận người dùng sẽ chơi loại nước cờ nào: bấm vào quân đã ăn, di chuyển bình thường,...

    const [gocBanCo, setGocBanCo] = useState(0);
    const [oanTuTiState, setOanTuTiState] = useState(false);
    const [endGameState, setEndGameState] = useState(false);
    const [endResult, setEndResult] = useState([]);
    const [gameTimeout, setGameTimeOut] = useState(0);
    const [quanCoBamVao, setQuanCoBamVao] = useState({ idQuanCo: null, row: null, col: null }); // Đây là giá trị khi bấm vào quân cờ sẽ có, phục vụ di chuyển ăn quân

    const [width, height] = useWindowSize();
    const minWidth = width >= 740 ? '600px' : `${Math.min(width, height)}px`;
    const wsRef = useRef(null);

    // const isDisable = false;
    const isDisable = thongTinOption.turnState !== clientId;

    useEffect(() => {
        let timer = setTimeout(() => {
            if (roomId && clientId) {
                // Tạo kết nối WebSocket khi component được mount
                const socket = new WebSocket(get_socket_game(roomId, clientId));

                // Lắng nghe sự kiện khi kết nối mở
                socket.onopen = () => {
                    console.log('Kết nối đến socket game');
                };

                // Lắng nghe sự kiện khi nhận được dữ liệu từ server
                socket.onmessage = (data) => {
                    try {
                        const mess_on_data = JSON.parse(data.data);

                        switch (mess_on_data.type) {
                            case "dat_quan_doanh_nhan":
                                const move1 = mess_on_data.move;
                                setLoaiViTri(1);
                                setThongTinOption(prev => {
                                    const newDataOption = { ...prev };
                                    newDataOption.boardMatrix = convertToNameMatrix(move1.boardMatrix);
                                    newDataOption.turnState = move1.turnState;
                                    newDataOption.chessman = move1.chessman;
                                    newDataOption.listOptionDisable = move1.listOptionDisable;
                                    return newDataOption
                                });
                                setThongBaoFunc(move1.notify);
                                setEndGameState(false);
                                break;

                            case "choi_co":
                                const move2 = mess_on_data.move;
                                setThongTinOption(prev => {
                                    const newDataOption = { ...prev };
                                    newDataOption.boardMatrix = convertToNameMatrix(move2.boardMatrix);
                                    newDataOption.turnState = move2.turnState;
                                    newDataOption.chessman = move2.chessman;
                                    newDataOption.listOptionDisable = move2.listOptionDisable;
                                    newDataOption.capturedPieces = move2.capturedPieces;
                                    newDataOption.newcapturedPieces = JSON.parse(move2.newcapturedPieces)
                                    return newDataOption
                                });

                                if (JSON.parse(move2.newcapturedPieces).length > 0 && move2.turnState === clientId) {
                                    setLoaiViTri(3);
                                }
                                else {
                                    setLoaiViTri(0);
                                }
                                // Đoạn này trả về quân cờ sẽ show ra cho người xem (quân cờ phóng to)
                                setQuanCoBamVao(prev => {
                                    const quanCoBamVaoNew = { ...prev };
                                    const viewChess = move2.viewChess;
                                    quanCoBamVaoNew.idQuanCo = viewChess.chess;
                                    quanCoBamVaoNew.row = viewChess.row;
                                    quanCoBamVaoNew.col = viewChess.col;
                                    return quanCoBamVaoNew;
                                })
                                capNhatDiemFunc(move2.scores)

                                // =================== 
                                setThongBaoFunc(move2.notify);
                                setIdQuanCoDauTu(null);
                                resetNuocCo();
                                break;

                            case "time":
                                setGameTimeOut(mess_on_data.time);
                                break;

                            case "roi_phong":
                                navigate("/")
                                break;

                            case "yeu_cau_oan_tu_ti":
                                setOanTuTiState(true);
                                break;

                            case "notify":
                                setThongBaoFunc(mess_on_data.notify);
                                break;

                            case "ket_thuc_oan_tu_ti":
                                setOanTuTiState(false);
                                const move3 = mess_on_data.move;

                                setThongTinOption(prev => {
                                    const newDataOption = { ...prev };
                                    newDataOption.boardMatrix = convertToNameMatrix(move3.boardMatrix);
                                    newDataOption.turnState = move3.turnState;
                                    newDataOption.chessman = move3.chessman;
                                    newDataOption.listOptionDisable = move3.listOptionDisable;
                                    newDataOption.newcapturedPieces = JSON.parse(move3.newcapturedPieces);
                                    return newDataOption
                                });
                                setLoaiViTri(0);
                                setThongBaoFunc(move3.notify);
                                setIdQuanCoDauTu(null);
                                capNhatDiemFunc(move2.scores)

                                break;

                            case "ket_thuc":
                                for (let newInfo of mess_on_data.endResult) {
                                    if (newInfo.id === getClientId()) {
                                        setPlayerScore(newInfo.newEloScore);
                                        break;
                                    }
                                }
                                setEndResult(mess_on_data.endResult);

                                setEndGameState(true);
                                break;

                            case "vi_tri":
                                setNuocCo(mess_on_data.move)
                                break;

                            default:
                                console.log("Không rõ là backend gửi cái gì đến frontend ở trong socket game");
                            // console.log(mess_on_data);
                        }
                    }
                    catch (exception) {
                        console.log("Ngoại lệ: " + exception);
                        // console.log(data.data);
                    }
                };

                // Lắng nghe sự kiện khi có lỗi xảy ra
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };

                // Lắng nghe sự kiện khi kết nối đóng
                socket.onclose = () => {
                    console.log('Socket game đóng');
                };

                // Lưu kết nối WebSocket vào state
                wsRef.current = socket;
            }
        }, 1000); // Đợi 1 giây trước khi tạo kết nối

        // Cleanup: Clear timeout nếu component unmount trước khi đã tạo kết nối
        return () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
            }
            clearTimeout(timer)
        };
    }, [roomId, clientId]);

    // Hàm này dùng để xoay bàn cờ lại 180 độ
    const xoayBanCo = () => {
        if (gocBanCo === 0) {
            setGocBanCo(180);
        }
        else {
            setGocBanCo(0);
        }
    }

    const resetNuocCo = () => {
        // Bước này để reset nước cờ mỗi lần nhận nước đi xon
        setNuocCo({
            duoiTenLua: {
                x: null,
                y: null,
                name: ""
            },
            dauTenLua: {
                x: null,
                y: null,
                name: ""
            },
            oBenDap: {
                x: null,
                y: null,
                name: ""
            }
        })
    }

    // =============== Các logic của game sẽ gửi đi ================

    // Hàm này dùng để gửi nước cờ đi dưới dạng json
    const guiNuocCo = (nuocCo) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify(nuocCo));
        }
        else {
            console.log('WebSocket không mở hoặc đã bị đóng lại.');
            // Xử lý hoặc thông báo lỗi tại đây nếu cần thiết
        }
    }

    // Đầu tư
    const dauTu = (rowIndex, colIndex) => {
        const nuocCoGuiDi = {
            type: "dau_tu",
            move: {
                chessman: idQuanCoDauTu,
                row: rowIndex,
                col: colIndex
            }
        }
        guiNuocCo(nuocCoGuiDi);
    }

    // Đặt quân doanh nhân
    const datQuanDoanhNhan = (tenOCo, rowIndex, colIndex) => {
        // row = 0, col = 3 || row = 7, col = 4
        if ((rowIndex === 0 && colIndex === 3) || (rowIndex === 7 && colIndex === 4)) {
            let datQuanDoanhNhanState = true;
            if (getConfirmStateFunc()) {
                datQuanDoanhNhanState = window.confirm(`Bạn muốn đặt quân doanh nhân thụ động tại vị trí ${tenOCo}?`);
            }
            if (datQuanDoanhNhanState) {
                const nuocCoGuiDi = {
                    type: "dat_quan_doanh_nhan",
                    move: {
                        row: rowIndex,
                        col: colIndex
                    }
                }
                guiNuocCo(nuocCoGuiDi);
            }
        } else {
            alert("Bạn chỉ có thể đặt quân doanh nhân thụ động tại vị trí D8 hoặc E1.");
        }
    }

    // Hàm này dùng để gửi vị trí lựa chọn là đầu, đuôi, bến đáp
    const guiViTri = (data) => {
        const nuocCoGuiDi = {
            type: "vi_tri",
            move: data
        }
        guiNuocCo(nuocCoGuiDi);
    }

    // Bấm vào ô cờ trong bàn cờ, sẽ nhận được các thông tin: Quân cờ ở ô đó, tên của ô cờ tại vị trí đó, tọa độ trong ô cờ
    // Trong này thực hiện tất cả tác vụ: đầu tư, chọn ô bến đáp, đuôi - đầu tên lửa.
    const bamVaoOCo = (quanCo, tenOCo, rowIndex, colIndex) => {
        // console.log(quanCo);
        if (idQuanCoDauTu === null) {
            // loại vị trí = 1 -> đặt quân doanh nhân
            // loại set vị trí = 1 thì chỉ do backend yêu cầu frontend khi bắt đầu game
            if (loaiViTri === 1) {
                datQuanDoanhNhan(tenOCo, rowIndex, colIndex);
            }
            else if (loaiViTri === 0) {
                // Quân cờ là cái quân trong bàn cờ, nếu trống là null
                if (quanCo === null) {
                    // Trường hợp 1 là chọn ô bến đáp ban đầu vì nó chưa có tên
                    if (nuocCo.oBenDap.name === "") {
                        // Ô bến đáp là ô đầu tiên, vậy nên nếu bấm vào một ô trống thì xem như reset lại nước cờ
                        guiViTri({
                            duoiTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            dauTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            oBenDap: {
                                x: colIndex,
                                y: rowIndex,
                                name: tenOCo
                            }
                        });
                    }
                    // Trường hợp 2 ô bến đáp trùng với ô hiện tại đang bấm => muốn di chuyển không ăn quân
                    else if (nuocCo.oBenDap.name === tenOCo) {
                        phongTenLua();
                    }
                    // Trường hợp 3 là chọn ô bến đáp khác
                    else if (nuocCo.oBenDap.name !== tenOCo) {
                        guiViTri({
                            duoiTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            dauTenLua: {
                                x: null,
                                y: null,
                                name: ""
                            },
                            oBenDap: {
                                x: colIndex,
                                y: rowIndex,
                                name: tenOCo
                            }
                        });
                    }
                }
                // Trường hợp bấm vào quân doanh nhân -> muốn quyết định không di chuyển
                else {
                    // Trường hợp bấm vào ô có quân cờ, nếu bấm vào quân doanh nhân của mình => đứng yên
                    if (quanCo === thongTinOption.chessman) {
                        quyetDinhKhongDiChuyen();
                    }
                    // Nếu bấm vào vị trí không phải quân doanh nhân => chọn đuôi và đầu
                    else {
                        if (nuocCo.oBenDap.name !== "" && nuocCo.duoiTenLua.name === "") {
                            guiViTri({
                                ...nuocCo,
                                duoiTenLua: {
                                    x: colIndex,
                                    y: rowIndex,
                                    name: tenOCo
                                }
                            });
                        }
                        else if (nuocCo.oBenDap.name !== "" && nuocCo.duoiTenLua.name !== "" && nuocCo.dauTenLua.name === "") {
                            guiViTri({
                                ...nuocCo,
                                dauTenLua: {
                                    x: colIndex,
                                    y: rowIndex,
                                    name: tenOCo
                                }
                            });
                        }
                    }
                }
            }
        }
        else {
            dauTu(rowIndex, colIndex);
        }
    };

    const bamVaoQuanCo = (quanCo, isMarker, rowIndex, colIndex) => {
        if (isMarker === true && quanCo !== null) {
            setQuanCoBamVao({ ...quanCoBamVao, row: rowIndex, col: colIndex });

            // Loại vị trí = 3 -> đây là trường hợp đã ăn quân, bây giờ bấm vào từng ô cờ để bật chế độ xem cờ
            if (loaiViTri === 3) {
                if (thongTinOption.newcapturedPieces.length > 0) {
                    // Lấy vị trí đầu đầu tiên của mảng (vì phải bấm từ đuôi lên đầu)
                    let [row0, col0] = thongTinOption.newcapturedPieces[0];
                    if (rowIndex === row0 && colIndex === col0) {
                        const nuocCoGuiDi = {
                            type: "xem_co",
                            move: {
                                row: rowIndex,
                                col: colIndex
                            }
                        }
                        guiNuocCo(nuocCoGuiDi);
                    }
                }
            }

        }
    };

    const tatXemCo = () => {
        const nuocCoGuiDi = {
            type: "tat_xem_co"
        }
        guiNuocCo(nuocCoGuiDi);
    }

    const phongTenLua = () => {
        /*
            Logic như sau: chỉ cần bấm vào ô bến đáp 2 lần, thì nó sẽ check:
                + Nếu đầy đủ đuôi, đầu, bến đáp => phóng ăn quân
                + Nếu chỉ có bến đáp => phóng không ăn quân
        */
        if (nuocCo.dauTenLua.name !== "" && nuocCo.duoiTenLua.name !== "" && nuocCo.oBenDap.name !== "") {
            let diChuyenAnQuanState = true;
            if (getConfirmStateFunc()) {
                diChuyenAnQuanState = window.confirm(`Bạn muốn thực hiện di chuyển ăn quân đến ô ${nuocCo.oBenDap.name}`)
            }
            if (diChuyenAnQuanState === true) {
                const nuocCoGuiDi = {
                    ...nuocCo,
                    type: "di_chuyen_an_quan"
                }
                guiNuocCo(nuocCoGuiDi);
            }
        }
        else if (nuocCo.oBenDap.name !== "") {
            let diChuyenKhongAnQuanState = true;
            if (getConfirmStateFunc()) {
                diChuyenKhongAnQuanState = window.confirm(`Bạn muốn thực hiện di chuyển không ăn quân đến ô ${nuocCo.oBenDap.name}`);
            }
            if (diChuyenKhongAnQuanState === true) {
                const nuocCoGuiDi = {
                    ...nuocCo,
                    type: "di_chuyen_khong_an_quan"
                }
                guiNuocCo(nuocCoGuiDi);
            }
        }

        resetNuocCo();
    }

    // Quyết định không di chuyển
    const quyetDinhKhongDiChuyen = () => {
        let quyetDinhKhongDiChuyenState = true;
        if (getConfirmStateFunc()) {
            quyetDinhKhongDiChuyenState = window.confirm("Bạn muốn thực hiện quyết định không di chuyển?");
        }
        if (quyetDinhKhongDiChuyenState) {
            const nuocCoGuiDi = {
                type: "quyet_dinh_khong_di_chuyen",
            }
            guiNuocCo(nuocCoGuiDi);
        }
    }

    // Oẳn tù tì, type: 0,1 value là Búa, kéo , bao
    const oanTuTi = (type, value) => {
        // Trường hợp type = 0 là lúc mở chức năng oẳn tù ti
        if (type === 0) {
            const nuocCoGuiDi = {
                type: "yeu_cau_oan_tu_ti",
            }
            guiNuocCo(nuocCoGuiDi);
        }
        if (type === 1) {
            const nuocCoGuiDi = {
                type: "gui_lua_chon_oan_tu_ti",
                move: value
            }
            guiNuocCo(nuocCoGuiDi);
        }
    }
    // Rời phòng
    const roiPhong = () => {
        const nuocCoGuiDi = {
            type: "ket_thuc",
        }
        guiNuocCo(nuocCoGuiDi);
    }

    // khi màn chơi kết thúc, trong vòng 10s có thể bấm chơi lại
    const choiLai = () => {
        const nuocCoGuiDi = {
            type: "choi_lai",
        }
        guiNuocCo(nuocCoGuiDi);
    }

    const outOfGame = () => {
        // navigate("/");
        console.log("Chức năng chưa hỗ trợ");
    }
    // ===============================================================

    return (
        <Fragment>
            <div style={{ width: minWidth, height: minWidth, transform: `rotateZ(${gocBanCo}deg)` }} className={style.PlayBoard}>
                <ImageBoard
                    boardMatrix={thongTinOption.boardMatrix}
                    nuocCo={nuocCo}
                    bamVaoOCoFunc={bamVaoOCo}
                    bamVaoQuanCoFunc={bamVaoQuanCo}
                    gocBanCo={gocBanCo}
                    isDisable={isDisable}
                    chessman={thongTinOption.chessman}
                />

                <Chessman
                    turnState={thongTinOption.turnState}
                    clientId={clientId}
                    tatXemCoFunc={tatXemCo}
                    gocBanCo={gocBanCo}
                    quanCoBamVao={quanCoBamVao}
                    boardSize={minWidth}
                />
            </div>
            <OptionsBox
                doiGocBanCoFunc={xoayBanCo}
                idQuanCoDauTu={idQuanCoDauTu}
                setIdQuanCoDauTuFunc={setIdQuanCoDauTu}
                roiPhongFunc={roiPhong}
                oanTuTiFunc={oanTuTi}
                danhSachQuanCo={thongTinOption.capturedPieces}
                danhSachCacChucNangDisable={thongTinOption.listOptionDisable}
            />
            <EndGame visible={endGameState} outFunc={outOfGame} choiLaifunc={choiLai} endResult={endResult} timeout={gameTimeout} />
            <OanTuTi visible={oanTuTiState} oanTuTiFunc={oanTuTi} timeout={gameTimeout} />
        </Fragment>
    )
}

export default memo(PlayBoard);
import style from './NPCRoom.module.scss';
import NPCPlayboard from './NPCPlayboard';
import NPCRoomHeader from './NPCRoomHeader';
import { useState } from 'react';

function NPCRoom() {
    const [diemSo, setDiemSo] = useState({ diemToi: 0, doiThu: 0 });
    const [luotCuaToi, setLuotCuaToi] = useState(true);
    const [notify, setNotify] = useState("");

    return (
        <div className={style.NPCRoom}>
            <NPCRoomHeader
                diem_doi_thu={diemSo.doiThu}
                diem_toi={diemSo.diemToi}
                thong_bao={notify}
                myTurn={luotCuaToi}
            />
            <NPCPlayboard
                luotCuaToi={luotCuaToi}
                setLuotCuaToiFunc={setLuotCuaToi}
                setNotifyFunc={setNotify}
                setDiemSofunc={setDiemSo}
                diemSo={diemSo}
            />
        </div>
    )
}

export default NPCRoom;
import { Fragment } from 'react';

import Room from '../components/room/Room';

function RoomPage() {

    return (
        <Fragment>
            <Room />
        </Fragment>
    )
};

export default RoomPage;
import style from './EndGame.module.scss';
import CenterBox from '../../base/box/CenterBox';
import { getClientId } from '../../constants/services';
import { useEffect } from 'react';
import { useState } from 'react';

function EndGame({ visible, endResult, timeout, choiLaifunc, outFunc }) {
    // endResult = [{ id: "12345", name: "Hổ con", score: 25 }, { id: "12354", name: "Rồng xanh", score: 25 }]
    const [endNotify, setEndNotify] = useState("");
    useEffect(() => {
        if (visible) {
            if (endResult !== null && endResult.length === 2) {
                const myId = getClientId();
                const myInfo = endResult.find(item => item.id === myId);
                const opponentInfo = endResult.find(item => item.id !== myId);

                if (myInfo !== undefined && opponentInfo !== undefined) {
                    // Kiểm tra điều kiện và xác định kết quả
                    if (myInfo.score > opponentInfo.score) {
                        setEndNotify("Chúc mừng bạn là người THẮNG cuộc");
                    } else if (myInfo.score < opponentInfo.score) {
                        setEndNotify("Chúc mừng bạn là người KHÔNG BỎ CUỘC");
                    } else {
                        setEndNotify("Chúc mừng thiện chí HỢP TÁC của bạn");
                    }
                }
            }
            else {
                setEndNotify("Trận đấu kết thúc");
            }
        }
    }, [endResult, visible]); // Thay đổi từ 'visible' thành 'endResult'

    return (
        <CenterBox visible={visible}>
            <div className={`${style.EndGame}`}>
                <h2>Rời phòng trong - {timeout}s</h2>
                <p className={style.end_notify}>{endNotify}</p>
                {/* <button onClick={() => outFunc()} className={style.option_btn}>Thoát</button> */}
                {
                    (endResult !== null) && <button onClick={() => choiLaifunc()} className={style.option_btn}>Chơi lại</button>
                }
            </div>
        </CenterBox>
    )
}

export default EndGame;
import style from './OptionsBox.module.scss';
import InvestBox from './InvestBox';
import { getConfirmStateFunc, setConfirmStateFunc } from '../../constants/services';
import { Fragment, useState, memo } from 'react';
import { AiFillAppstore, AiOutlineClose } from "react-icons/ai";

/* Hàm setLoaiViTriFunc sẽ gửi lên 3 loại tham số:
    + 0 nếu muốn set thông tin đầu tên lửa
    + 1 nếu muốn set thông tin đuôi tên lửa
    + 2 nếu muốn set thông tin bến đáp
*/
function OptionsBox({
    doiGocBanCoFunc,
    setIdQuanCoDauTuFunc,
    oanTuTiFunc,
    roiPhongFunc,
    idQuanCoDauTu,
    danhSachQuanCo,
    danhSachCacChucNangDisable,
}) {
    const [optionsBoxState, setOptionsBoxState] = useState(false);
    const [confirmState, setConfirmState] = useState(getConfirmStateFunc());

    const roiPhong = () => {
        let roiPhongState = true;
        if (getConfirmStateFunc()) {
            roiPhongState = window.confirm("Nếu rời phòng, ván chơi sẽ kết thúc.");
        }
        if (roiPhongState) {
            roiPhongFunc();
        }
    }

    const xoayBanCo = () => {
        doiGocBanCoFunc();
    }

    const oanTuTi = () => {
        let oanTuTiState = true;
        if (getConfirmStateFunc()) {
            oanTuTiState = window.confirm("Bạn muốn thực hiện oẳn tù tì?");
        }
        if (oanTuTiState) {
            setOptionsBoxState(false);
            oanTuTiFunc(0, null);
        }
    }

    const toggleConfirmState = () => {
        setConfirmState(!confirmState);
        setConfirmStateFunc(!confirmState);
    }
    return (
        <Fragment>

            <div style={{ display: `${optionsBoxState ? 'block' : 'none'}` }} className={style.OptionsBox}>
                <div className={style.header}>
                    <span onClick={() => { setOptionsBoxState(false); }}><AiOutlineClose /></span>
                </div>

                {/* Oẳn tù tì */}
                <div className={`${style.option}`}>
                    <div
                        style={{ backgroundColor: 'var(--blur_blue)' }}
                        className={`${style.item_option}`}>
                        <span>Hỏi trước khi di chuyển:
                            <label className={style.switch}>
                                <input onChange={() => toggleConfirmState()} type="checkbox" checked={confirmState} />
                                <span className={`${style.slider} ${style.round}`}></span>
                            </label>
                        </span>
                    </div>
                </div>

                {/* Chọn đầu tư */}
                <div className={`${style.option}`}>
                    < InvestBox
                        isDiable={danhSachCacChucNangDisable.chon_dau_tu}
                        idQuanCoDauTu={idQuanCoDauTu}
                        danhSachQuanCo={danhSachQuanCo}
                        setIdQuanCoDauTuFunc={setIdQuanCoDauTuFunc} />
                </div>

                {/* Oẳn tù tì */}
                <div className={`${style.option}`}>
                    <div
                        onClick={() => oanTuTi()}
                        style={{ backgroundColor: 'var(--blur_gray)' }}
                        className={`${style.item_option} ${danhSachCacChucNangDisable.oan_tu_ti && "btn-disabled"}`}>
                        <span>Oẳn tù tì</span>
                    </div>
                </div>

                {/* Xoay bàn cờ 180 độ */}
                <div className={`${style.option}`}>
                    <div
                        onClick={() => xoayBanCo()}
                        style={{ backgroundColor: 'var(--green)' }}
                        className={`${style.item_option}`}>
                        <span>Xoay bàn cờ</span>
                    </div>
                </div>

                {/* Rời phòng */}
                <div className={`${style.option}`}>
                    <div
                        onClick={() => roiPhong()}
                        style={{ backgroundColor: 'var(--red)' }}
                        className={`${style.item_option}`}>
                        <span>Rời phòng</span>
                    </div>
                </div>
            </div>

            {/* Chức năng optionBox hiden, tức là ô vuông chức năng bình thường sẽ bị ẩn */}
            <div style={{ display: `${optionsBoxState ? 'none' : 'flex'}` }} onClick={() => setOptionsBoxState(true)} className={style.OptionsBox_hiden}>
                <span className={style.option_icon}>
                    <AiFillAppstore />
                </span>
            </div>

        </Fragment>


    )
}

export default memo(OptionsBox);
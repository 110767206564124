const defaultMatrix = [
    [
        { number: null, isMarker: false, name: 'A8', deleteEnable: false },
        { number: null, isMarker: false, name: 'B8', deleteEnable: false },
        { number: null, isMarker: false, name: 'C8', deleteEnable: false },
        { number: null, isMarker: false, name: 'D8', deleteEnable: false },
        { number: null, isMarker: false, name: 'E8', deleteEnable: false },
        { number: null, isMarker: false, name: 'G8', deleteEnable: false },
        { number: null, isMarker: false, name: 'H8', deleteEnable: false },
        { number: null, isMarker: false, name: 'I8', deleteEnable: false },
    ],
    [
        { number: null, isMarker: false, name: 'A7', deleteEnable: false },
        { number: null, isMarker: false, name: 'B7', deleteEnable: false },
        { number: null, isMarker: false, name: 'C7', deleteEnable: false },
        { number: null, isMarker: false, name: 'D7', deleteEnable: false },
        { number: null, isMarker: false, name: 'E7', deleteEnable: false },
        { number: null, isMarker: false, name: 'G7', deleteEnable: false },
        { number: null, isMarker: false, name: 'H7', deleteEnable: false },
        { number: null, isMarker: false, name: 'I7', deleteEnable: false },
    ],
    [
        { number: null, isMarker: false, name: 'A6', deleteEnable: false },
        { number: null, isMarker: false, name: 'B6', deleteEnable: false },
        { number: null, isMarker: false, name: 'C6', deleteEnable: false },
        { number: null, isMarker: false, name: 'D6', deleteEnable: false },
        { number: null, isMarker: false, name: 'E6', deleteEnable: false },
        { number: null, isMarker: false, name: 'G6', deleteEnable: false },
        { number: null, isMarker: false, name: 'H6', deleteEnable: false },
        { number: null, isMarker: false, name: 'I6', deleteEnable: false },
    ],
    [
        { number: null, isMarker: false, name: 'A5', deleteEnable: false },
        { number: null, isMarker: false, name: 'B5', deleteEnable: false },
        { number: null, isMarker: false, name: 'C5', deleteEnable: false },
        { number: null, isMarker: false, name: 'D5', deleteEnable: false },
        { number: null, isMarker: false, name: 'E5', deleteEnable: false },
        { number: null, isMarker: false, name: 'G5', deleteEnable: false },
        { number: null, isMarker: false, name: 'H5', deleteEnable: false },
        { number: null, isMarker: false, name: 'I5', deleteEnable: false },
    ],
    [
        { number: null, isMarker: false, name: 'A4', deleteEnable: false },
        { number: null, isMarker: false, name: 'B4', deleteEnable: false },
        { number: null, isMarker: false, name: 'C4', deleteEnable: false },
        { number: null, isMarker: false, name: 'D4', deleteEnable: false },
        { number: null, isMarker: false, name: 'E4', deleteEnable: false },
        { number: null, isMarker: false, name: 'G4', deleteEnable: false },
        { number: null, isMarker: false, name: 'H4', deleteEnable: false },
        { number: null, isMarker: false, name: 'I4', deleteEnable: false },
    ],
    [
        { number: null, isMarker: false, name: 'A3', deleteEnable: false },
        { number: null, isMarker: false, name: 'B3', deleteEnable: false },
        { number: null, isMarker: false, name: 'C3', deleteEnable: false },
        { number: null, isMarker: false, name: 'D3', deleteEnable: false },
        { number: null, isMarker: false, name: 'E3', deleteEnable: false },
        { number: null, isMarker: false, name: 'G3', deleteEnable: false },
        { number: null, isMarker: false, name: 'H3', deleteEnable: false },
        { number: null, isMarker: false, name: 'I3', deleteEnable: false },
    ],
    [
        { number: null, isMarker: false, name: 'A2', deleteEnable: false },
        { number: null, isMarker: false, name: 'B2', deleteEnable: false },
        { number: null, isMarker: false, name: 'C2', deleteEnable: false },
        { number: null, isMarker: false, name: 'D2', deleteEnable: false },
        { number: null, isMarker: false, name: 'E2', deleteEnable: false },
        { number: null, isMarker: false, name: 'G2', deleteEnable: false },
        { number: null, isMarker: false, name: 'H2', deleteEnable: false },
        { number: null, isMarker: false, name: 'I2', deleteEnable: false },
    ],
    [
        { number: null, isMarker: false, name: 'A1', deleteEnable: false },
        { number: null, isMarker: false, name: 'B1', deleteEnable: false },
        { number: null, isMarker: false, name: 'C1', deleteEnable: false },
        { number: null, isMarker: false, name: 'D1', deleteEnable: false },
        { number: null, isMarker: false, name: 'E1', deleteEnable: false },
        { number: null, isMarker: false, name: 'G1', deleteEnable: false },
        { number: null, isMarker: false, name: 'H1', deleteEnable: false },
        { number: null, isMarker: false, name: 'I1', deleteEnable: false },
    ]
]

const initMatrix = [
    [{ number: null, isMarker: false, name: 'A8', deleteEnable: false }, { number: null, isMarker: false, name: 'B8', deleteEnable: false }, { number: null, isMarker: false, name: 'C8', deleteEnable: false }, { number: null, isMarker: false, name: 'D8', deleteEnable: false }, { number: null, isMarker: false, name: 'E8', deleteEnable: false }, { number: null, isMarker: false, name: 'F8', deleteEnable: false }, { number: null, isMarker: false, name: 'G8', deleteEnable: false }, { number: null, isMarker: false, name: 'H8', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A7', deleteEnable: false }, { number: 40, isMarker: false, name: 'B7', deleteEnable: false }, { number: 40, isMarker: false, name: 'C7', deleteEnable: false }, { number: 40, isMarker: false, name: 'D7', deleteEnable: false }, { number: null, isMarker: false, name: 'E7', deleteEnable: false }, { number: 40, isMarker: false, name: 'F7', deleteEnable: false }, { number: 40, isMarker: false, name: 'G7', deleteEnable: false }, { number: null, isMarker: false, name: 'H7', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A6', deleteEnable: false }, { number: 40, isMarker: false, name: 'B6', deleteEnable: false }, { number: 40, isMarker: false, name: 'C6', deleteEnable: false }, { number: null, isMarker: false, name: 'D6', deleteEnable: false }, { number: 40, isMarker: false, name: 'E6', deleteEnable: false }, { number: 40, isMarker: false, name: 'F6', deleteEnable: false }, { number: 40, isMarker: false, name: 'G6', deleteEnable: false }, { number: null, isMarker: false, name: 'H6', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A5', deleteEnable: false }, { number: 40, isMarker: false, name: 'B5', deleteEnable: false }, { number: 40, isMarker: false, name: 'C5', deleteEnable: false }, { number: null, isMarker: false, name: 'D5', deleteEnable: false }, { number: 40, isMarker: false, name: 'E5', deleteEnable: false }, { number: 40, isMarker: false, name: 'F5', deleteEnable: false }, { number: 40, isMarker: false, name: 'G5', deleteEnable: false }, { number: null, isMarker: false, name: 'H5', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A4', deleteEnable: false }, { number: 40, isMarker: false, name: 'B4', deleteEnable: false }, { number: 40, isMarker: false, name: 'C4', deleteEnable: false }, { number: 40, isMarker: false, name: 'D4', deleteEnable: false }, { number: null, isMarker: false, name: 'E4', deleteEnable: false }, { number: 40, isMarker: false, name: 'F4', deleteEnable: false }, { number: 40, isMarker: false, name: 'G4', deleteEnable: false }, { number: null, isMarker: false, name: 'H4', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A3', deleteEnable: false }, { number: 40, isMarker: false, name: 'B3', deleteEnable: false }, { number: 40, isMarker: false, name: 'C3', deleteEnable: false }, { number: 40, isMarker: false, name: 'D3', deleteEnable: false }, { number: null, isMarker: false, name: 'E3', deleteEnable: false }, { number: 40, isMarker: false, name: 'F3', deleteEnable: false }, { number: 40, isMarker: false, name: 'G3', deleteEnable: false }, { number: null, isMarker: false, name: 'H3', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A2', deleteEnable: false }, { number: 40, isMarker: false, name: 'B2', deleteEnable: false }, { number: 40, isMarker: false, name: 'C2', deleteEnable: false }, { number: null, isMarker: false, name: 'D2', deleteEnable: false }, { number: 40, isMarker: false, name: 'E2', deleteEnable: false }, { number: 40, isMarker: false, name: 'F2', deleteEnable: false }, { number: 40, isMarker: false, name: 'G2', deleteEnable: false }, { number: null, isMarker: false, name: 'H2', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A1', deleteEnable: false }, { number: null, isMarker: false, name: 'B1', deleteEnable: false }, { number: null, isMarker: false, name: 'C1', deleteEnable: false }, { number: null, isMarker: false, name: 'D1', deleteEnable: false }, { number: null, isMarker: false, name: 'E1', deleteEnable: false }, { number: null, isMarker: false, name: 'F1', deleteEnable: false }, { number: null, isMarker: false, name: 'G1', deleteEnable: false }, { number: null, isMarker: false, name: 'H1', deleteEnable: false }]
];

const npcMatrix = [
    [{ number: null, isMarker: false, name: 'A8', deleteEnable: false }, { number: null, isMarker: false, name: 'B8', deleteEnable: false }, { number: null, isMarker: false, name: 'C8', deleteEnable: false }, { number: 0, isMarker: false, name: 'D8', deleteEnable: false }, { number: null, isMarker: false, name: 'E8', deleteEnable: false }, { number: null, isMarker: false, name: 'F8', deleteEnable: false }, { number: null, isMarker: false, name: 'G8', deleteEnable: false }, { number: null, isMarker: false, name: 'H8', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A7', deleteEnable: false }, { number: 7, isMarker: false, name: 'B7', deleteEnable: false },    { number: 15, isMarker: false, name: 'C7', deleteEnable: false },   { number: 27, isMarker: false, name: 'D7', deleteEnable: false }, { number: null, isMarker: false, name: 'E7', deleteEnable: false }, { number: 20, isMarker: false, name: 'F7', deleteEnable: false }, { number: 4, isMarker: false, name: 'G7', deleteEnable: false }, { number: null, isMarker: false, name: 'H7', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A6', deleteEnable: false }, { number: 33, isMarker: false, name: 'B6', deleteEnable: false },   { number: 8, isMarker: false, name: 'C6', deleteEnable: false },    { number: null, isMarker: false, name: 'D6', deleteEnable: false }, { number: 10, isMarker: false, name: 'E6', deleteEnable: false }, { number: 9, isMarker: false, name: 'F6', deleteEnable: false }, { number: 31, isMarker: false, name: 'G6', deleteEnable: false }, { number: null, isMarker: false, name: 'H6', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A5', deleteEnable: false }, { number: 22, isMarker: false, name: 'B5', deleteEnable: false },   { number: 25, isMarker: false, name: 'C5', deleteEnable: false },   { number: null, isMarker: false, name: 'D5', deleteEnable: false }, { number: 29, isMarker: false, name: 'E5', deleteEnable: false }, { number: 30, isMarker: false, name: 'F5', deleteEnable: false }, { number: 37, isMarker: false, name: 'G5', deleteEnable: false }, { number: null, isMarker: false, name: 'H5', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A4', deleteEnable: false }, { number: 14, isMarker: false, name: 'B4', deleteEnable: false },   { number: 19, isMarker: false, name: 'C4', deleteEnable: false },   { number: 17, isMarker: false, name: 'D4', deleteEnable: false }, { number: null, isMarker: false, name: 'E4', deleteEnable: false }, { number: 16, isMarker: false, name: 'F4', deleteEnable: false }, { number: 24, isMarker: false, name: 'G4', deleteEnable: false }, { number: null, isMarker: false, name: 'H4', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A3', deleteEnable: false }, { number: 11, isMarker: false, name: 'B3', deleteEnable: false },   { number: 28, isMarker: false, name: 'C3', deleteEnable: false },   { number: 12, isMarker: false, name: 'D3', deleteEnable: false }, { number: null, isMarker: false, name: 'E3', deleteEnable: false }, { number: 13, isMarker: false, name: 'F3', deleteEnable: false }, { number: 21, isMarker: false, name: 'G3', deleteEnable: false }, { number: null, isMarker: false, name: 'H3', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A2', deleteEnable: false }, { number: 5, isMarker: false, name: 'B2', deleteEnable: false },    { number: 26, isMarker: false, name: 'C2', deleteEnable: false },   { number: null, isMarker: false, name: 'D2', deleteEnable: false }, { number: 23, isMarker: false, name: 'E2', deleteEnable: false }, { number: 18, isMarker: false, name: 'F2', deleteEnable: false }, { number: 6, isMarker: false, name: 'G2', deleteEnable: false }, { number: null, isMarker: false, name: 'H2', deleteEnable: false }],
    [{ number: null, isMarker: false, name: 'A1', deleteEnable: false }, { number: null, isMarker: false, name: 'B1', deleteEnable: false }, { number: null, isMarker: false, name: 'C1', deleteEnable: false }, { number: null, isMarker: false, name: 'D1', deleteEnable: false }, { number: 1, isMarker: false, name: 'E1', deleteEnable: false }, { number: null, isMarker: false, name: 'F1', deleteEnable: false }, { number: null, isMarker: false, name: 'G1', deleteEnable: false }, { number: null, isMarker: false, name: 'H1', deleteEnable: false }]
];

// Hàm này nhận tham số là matrix có 2 thuộc tính number và isMarker,
// và chuyển thành ma trận có thêm thuộc tính là name (mục đích chỉ để hiển thị trong option box)
function convertToNameMatrix(matrix) {
    for (let i = 0; i < 8; i++) {
        for (let j = 0; j < 8; j++) {
            defaultMatrix[i][j].number = matrix[i][j].number;
            defaultMatrix[i][j].isMarker = matrix[i][j].isMarker;
            defaultMatrix[i][j].deleteEnable = matrix.deleteEnable;
        }
    }
    return defaultMatrix;
}
export default convertToNameMatrix;
export { initMatrix, npcMatrix };
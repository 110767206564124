import style from './Login.module.scss';

import { useState } from 'react';
import CenterBox from '../../base/box/CenterBox';
import { fetchData, getPlayerInfo, setPlayerName, setPlayerScore } from '../../constants/services';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { api, methods, getAPI_tam_thoi } from '../../constants/api';

function Login({ visible, setVisibleFunc }) {
    const [name, setName] = useState(getPlayerInfo().name);
    const [info, setInfo] = useState({ email: "", password: "" });
    const [loginError, setLoginError] = useState("");
    const [type, setType] = useState(0);
    const [viewState, setViewState] = useState(false);

    const closeLogin = () => {
        // Đoạn này dùng để clear những thông tin đã điền trước khi đóng component
        setInfo({ email: "", password: "" });
        setLoginError("")
        setType(0)
        setViewState(false)
        setVisibleFunc(false);
    }

    const submit = async () => {
        if (type === 0) {
            if (name !== "" && name.trim().length !== 0) {
                setPlayerName(name);
                setVisibleFunc(false);
            }
        } else if (type === 1) {
            const emailPattern = /@.*\.com$/;
            if (info.email === "" && info.email.trim().length === 0) {
                setLoginError("Email trống.")
            }
            else if (!emailPattern.test(info.email)) {
                setLoginError("Email phải theo định dạng _@_.com .")
            }
            else if (info.password === "" && info.password.trim().length === 0) {
                setLoginError("Mật khẩu trống.")
            } else {
                try {
                    setLoginError("");
                    const result = await fetchData(getAPI_tam_thoi(api.lay_thong_tin), methods.post, info);
                    if (result.state === true) {
                        // console.log(result);
                        setPlayerName(result.name);
                        setPlayerScore(result.elo);
                        closeLogin();
                    } else{
                        setLoginError(result.value)
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const changeInput = (e) => {
        setName(e.target.value);
    }
    const keypressInput = (e) => {
        if (e.keyCode === 13) {
            submit();
        }
    }
    return (
        <CenterBox visible={visible}>
            <div className={style.choice_conteiner}>
                <span onClick={() => setType(0)} className={`${style.choice_btn} ${type === 0 ? style.choice_btn_activate : null}`}>Đặt biệt hiệu</span>
                <span onClick={() => setType(1)} className={`${style.choice_btn} ${type === 1 ? style.choice_btn_activate : null}`}>Lấy thông tin</span>
            </div>
            <div className={style.option_box} style={{ display: type === 0 ? "block" : "none" }}>
                <div className={style.input_container}>
                    <input value={name} onChange={changeInput} onKeyDown={keypressInput} type="text" placeholder='Điền biệt danh của bạn.' />
                </div>
            </div>

            <form className={style.option_box} style={{ display: type === 1 ? "block" : "none" }}>
                <p className={style.login_error}>{loginError}</p>
                <div className={style.input_container}>
                    <input autoComplete='email' value={info.email} onChange={(e) => setInfo({ ...info, email: e.target.value })} onKeyDown={keypressInput} type="email" placeholder='email' />
                </div>

                <div className={style.input_container}>
                    <input autoComplete='current-password' value={info.password} onChange={(e) => setInfo({ ...info, password: e.target.value })} onKeyDown={keypressInput} type={viewState ? "text" : "password"} placeholder='Mật khẩu' />
                    <span onClick={() => setViewState(!viewState)} className={style.xem_mat_khau_btn}>{viewState ? <AiFillEye /> : <AiFillEyeInvisible />}</span>
                </div>
            </form>
            <div className={style.button_container}>
                <button className={`bg_white`} onClick={() => closeLogin()}>Thoát</button>
                <button className={`${((name !== "" && name.trim().length !== 0) || (info.email !== "" && info.email.trim().length !== 0 && info.password !== "" && info.password.trim().length !== 0)) ? "bg_blue" : "bg-disabled"}`} onClick={submit}>Gửi</button>
            </div>
        </CenterBox>
    )
}

export default Login;
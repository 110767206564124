import { Fragment } from 'react';

function EmptyLayout({ children }) {

    return (
        <Fragment>
            {children}
        </Fragment>
    )
};

export default EmptyLayout;